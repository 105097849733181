<template>
  <div>
    <div class="text-center ml-2">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Fly to
            <v-icon>
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="
              flyToOpenPopUpByName({
                pointName: item.pointName,
                options: item.options ? item.options : {}
              })
            "
          >
            <v-list-item-title>{{ item.pointName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
/*

Lyf Bondi Junction
Quest on Wickham Terrace
Oakwood Hackney
Quest Ringwood
Quest Port Adelaide
Quest Blacktown




*/
import { eventBus } from "../../main";

import { mapState } from "vuex";

export default {
  name: "PipeLineShortListMenu",
  components: {
    //  maptivateInfoButton
  },
  data: () => ({
    dontOpenSideTimeout: null,
    items: [
      {
        pointName: "Quest on Wickham Terrace",
        options: {
          bounds: [
            [152.99720008375255, -27.483414616868473],
            [153.053, -27.45442230823945]
          ]
        }
      },
      {
        pointName: "Quest Bairnsdale",
        options: {
          bounds: [
            [147.59238377938158, -37.84023378540803],
            [147.6727740960087, -37.80693848094442]
          ]
        }
      },

      {
        pointName: "lyf Bondi Junction",
        options: {
          zoom: 14.5
        }
      },
      { pointName: "Oakwood Hackney" },
      { pointName: "Quest Ringwood" },
      { pointName: "Quest Port Adelaide North Parade" },
      { pointName: "Quest Blacktown" },
      { pointName: "Quest Williams Landing" },
      { pointName: "Quest Goulburn North" },
      { pointName: "Quest Southport" },
      {
        pointName: "Conference 2024",
        options: {
          bounds: [
            [130.92248695742205, -25.403331712992994],
            [131.10104057076194, -25.134651001433667]
          ]
        }
      }
    ]
  }),

  props: {},
  computed: {
    ...mapState({
      //allTaxonomiesLookup: state => state.markers.allTaxonomiesLookup
      range: state => state.filters.range
    })
  },

  methods: {
    flyToOpenPopUpByName(targetPoint) {
      //let flyToBbOnFilter = JSON.parse(JSON.stringify(this.flyToBbOnFilter));
      this.$store.dispatch("updateMinDetails", true);

      eventBus.$emit("nav_layout_closeMenu");
      eventBus.$emit("remote-ui-layercontrol-close");
      try {
        //dont open side bar;
        //this.$store.dispatch("updateHoldOnSideBarOpen", true);
        try {
          if (this.dontOpenSideTimeout) clearTimeout(this.dontOpenSideTimeout);
        } catch (error) {
          // do nothingk
        }
        this.dontOpenSideTimeout = setTimeout(() => {
          //this.$store.dispatch("updateHoldOnSideBarOpen", false);
        }, 2000);
      } catch (error) {
        //do nthing;
      }

      let newRange = JSON.parse(JSON.stringify(this.range));

      if (this.range[0] == 2) {
        newRange[0] = 1;
      }

      newRange[0] = 1;
      newRange[1] = 2;

      if (JSON.stringify(this.range) != JSON.stringify(this.newRange)) {
        this.$store.dispatch("filters_controlsUpdate_flyToBbOnFilter", false);
        this.$store.dispatch("filters_controlsUpdateRange", newRange);
      }

      eventBus.$emit("nav_map_flyToOpenPopUpByName", targetPoint);
    }
  },
  mounted() {}
};
</script>

<style scoped></style>
