<template>
  <div
    style="width:0;height:0;
    overflow: visible;"
  >
    <div class="mobileSet layerControlWrapper">
      <div
        class="layerControlButtons floatingLayerControlButtons"
        v-if="!showLayer"
      >
        <div class="d-flex flex-row">
          <v-range-slider
            v-if="false"
            class="hasWhiteBackground"
            v-show="!minimzeInterface && $vuetify.breakpoint.mdAndUp"
            style="width:250px"
            v-model="range"
            :tick-labels="ticksLabels"
            :max="2"
            step="1"
            ticks="always"
            tick-size="4"
          ></v-range-slider>

          <div
            v-show="!minimzeInterface && $vuetify.breakpoint.mdAndUp"
            class="fakeCheckbox fakeCheckboxNew brand hasWhiteBackground  fixedHight pt-1"
          >
            <template v-for="(item, index) in ticksLabels">
              <v-btn
                class="pl-1 pr-3"
                :color="'black'"
                style=""
                :key="`toggle_${item}`"
                @click="
                  autoPlay = false;
                  toggleRangeSlider(index);
                "
              >
                <v-icon
                  v-if="!(range[0] <= index && index <= range[1])"
                  color="primary darken-3"
                  >mdi-checkbox-blank-outline
                </v-icon>
                <v-icon
                  v-if="range[0] <= index && index <= range[1]"
                  color="primary darken-3"
                  >mdi-checkbox-marked
                </v-icon>

                <div class="checkboxDiv" :class="'black--text'">
                  {{ item }}
                </div>
              </v-btn>
            </template>
          </div>

          <v-select
            v-show="!minimzeInterface && $vuetify.breakpoint.mdAndUp"
            class="px-1"
            style="width:150px"
            v-model="regionSelect"
            :items="[{ key: 'all', name: 'All' }, ...regionSelectOptions]"
            item-text="name"
            item-value="key"
            label="Regions"
            background-color="primary"
            item-color="black"
            color="grey"
            dense
            outlined
          ></v-select>

          <v-select
            v-show="!minimzeInterface && $vuetify.breakpoint.lgAndUp"
            class="px-1"
            style="width:120px"
            v-model="awardSelect"
            :items="[{ key: 'all', name: 'None' }, ...awardSelectOptions]"
            item-text="name"
            item-value="key"
            label="Award"
            background-color="primary"
            item-color="555"
            color="555"
            dense
            outlined
          ></v-select>

          <v-btn
            :title="`${!showLayer ? 'Show' : 'Close'} Layer Filters`"
            z-depth="0"
            depressed
            notsmall
            class="ml-2 pa-0"
            :color="!showLayer ? appSettings.filterLegendButtonColor : 'white'"
            @click="showLayer = !showLayer"
          >
            <v-icon v-if="showLayer">mdi-close-circle </v-icon>
            <v-icon v-if="!showLayer" color="white">mdi-layers-outline </v-icon>
          </v-btn>
        </div>
      </div>

      <div v-show="showLayer" class="layerWrapper" style="">
        <v-card :width="$vuetify.breakpoint.smAndUp ? '400px' : '300px'">
          <v-toolbar class="noDropShadow" color="" dense height="35">
            <v-toolbar-title>Layer Filters</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon @click="resetFilters()" title="Reset Filters">
              <v-icon>mdi-restart</v-icon>
            </v-btn>

            <v-btn icon @click="showLayer = false" title="Close Layer Filters">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="pt-0">
            <div class="toggleWrapper">
              <v-btn-toggle
                class="fakeCheckbox fakeCheckboxNew brand"
                v-model="layers"
                multiple
                style="flex-direction: row; ;flex-wrap: wrap;"
              >
                <template v-for="item in setup_bools">
                  <v-btn
                    class="pl-1 pr-3"
                    v-show="categoryLookup[item.key].visible"
                    :key="item.key"
                    :value="item.key"
                    :color="layers.includes(item.key) ? '#f7f7e170' : null"
                    style=""
                  >
                    <v-icon
                      v-if="!layers.includes(item.key)"
                      :color="categoryLookup[item.key].color"
                      >mdi-checkbox-blank-outline
                    </v-icon>
                    <v-icon
                      v-if="layers.includes(item.key)"
                      :color="categoryLookup[item.key].color"
                      >mdi-checkbox-marked
                    </v-icon>

                    <div
                      class="checkboxDiv"
                      :class="
                        layers.includes(item.key)
                          ? 'black--text text--lighten-1'
                          : 'secondary--text  text--lighten-2 '
                      "
                    >
                      {{
                        item.title.replace("Quest Apartment Hotels", "Quest")
                      }}
                    </div>
                  </v-btn>
                </template>
              </v-btn-toggle>
            </div>

            <div class="d-flex flex-row mt-4">
              <v-select
                style="width:50%"
                v-model="regionSelect"
                :items="[{ key: 'all', name: 'All' }, ...regionSelectOptions]"
                item-text="name"
                item-value="key"
                label="Regions"
              ></v-select>

              <v-select
                style="width:50%"
                v-model="countrySelect"
                :items="[{ key: 'all', name: 'All' }, ...countrySelectOptions]"
                item-text="name"
                item-value="key"
                label="Country"
              ></v-select>
            </div>

            <div class="d-flex flex-row secondrow ">
              <v-select
                style="width:55%"
                v-model="programRadio"
                :items="[
                  { key: 'all', name: 'Show All' },
                  ...programRadioOptions
                ]"
                item-text="name"
                item-value="key"
                label="Programs"
              ></v-select>

              <v-select
                style="width:45%"
                v-model="awardSelect"
                :items="[{ key: 'all', name: 'None' }, ...awardSelectOptions]"
                item-text="name"
                item-value="key"
                label="Award"
              ></v-select>
            </div>

            <div class="d-flex flex-row thirdrow" v-if="false">
              <v-btn icon @click="autoPlay = !autoPlay">
                <v-icon>{{ autoPlay ? "mdi-stop" : "mdi-play" }}</v-icon>
              </v-btn>

              <v-range-slider
                style="width:60%"
                v-model="range"
                :tick-labels="ticksLabels"
                :max="2"
                step="1"
                ticks="always"
                tick-size="4"
              ></v-range-slider>
            </div>

            <div class="d-flex flex-row thirdrow">
              <v-btn
                icon
                @click="autoPlay = !autoPlay"
                style="height:30px;width:25px; margin-left:-5px"
              >
                <v-icon>{{ autoPlay ? "mdi-stop" : "mdi-play" }}</v-icon>
              </v-btn>

              <div class="fakeCheckbox fakeCheckboxNew brand">
                <template v-for="(item, index) in ticksLabels">
                  <v-btn
                    class="pl-1 pr-0"
                    :color="'#f7f7e170'"
                    style=""
                    :key="`toggle_${item}`"
                    @click="
                      autoPlay = false;
                      toggleRangeSlider(index);
                    "
                  >
                    <v-icon
                      v-if="!(range[0] <= index && index <= range[1])"
                      color="primary darken-2"
                      >mdi-checkbox-blank-outline
                    </v-icon>
                    <v-icon
                      v-if="range[0] <= index && index <= range[1]"
                      color="primary darken-2"
                      >mdi-checkbox-marked
                    </v-icon>

                    <div
                      class="checkboxDiv"
                      :class="
                        layers.includes(item)
                          ? 'black--text text--lighten-1'
                          : 'secondary--text  text--lighten-2 '
                      "
                    >
                      {{ item }}
                    </div>
                  </v-btn>
                </template>
              </div>
            </div>

            <v-divider class="mt-1 mb-1"></v-divider>

            <div class="toggleWrapper">
              <v-btn-toggle
                class="fakeCheckbox fakeCheckboxMapboxLayers"
                v-model="mapboxLayers"
                multiple
                style="flex-direction: row; flex-wrap: wrap;;"
              >
                <template v-for="item in mapboxStyleFilters">
                  <v-btn
                    class="pl-1 pr-3"
                    :key="item.id"
                    :value="item.id"
                    v-bind="iconSize"
                    style=""
                  >
                    <v-icon
                      v-bind="iconSize"
                      v-if="!mapboxLayers.includes(item.id)"
                      :color="item.color"
                      >mdi-checkbox-blank-outline
                    </v-icon>
                    <v-icon
                      v-bind="iconSize"
                      v-if="mapboxLayers.includes(item.id)"
                      :color="item.color"
                      >mdi-checkbox-marked
                    </v-icon>

                    <div style="margin-left:2px" class="checkboxDiv">
                      {{ item.title }}
                    </div>
                  </v-btn>
                </template>
              </v-btn-toggle>
            </div>
            <v-divider class="mt-1 mb-1"></v-divider>

            <div class="d-flex flex-row thirdrow flex-wrap">
              <div style="margin-left:-4px" class="mt-2">
                <v-checkbox
                  v-model="flyToBbOnFilter"
                  label="Focus After Filter"
                  color="#a59a9a"
                  hide-details
                ></v-checkbox>
              </div>

              <div style="margin-left:4px" class="mt-2">
                <v-checkbox
                  v-model="showStatusLabels"
                  label="Show Region Details"
                  color="#a59a9a"
                  hide-details
                ></v-checkbox>
              </div>
            </div>

            <div class="d-flex flex-row thirdrow flex-wrap">
              <div style="margin-left:-4px" class="mt-2">
                <v-checkbox
                  v-model="minimzeInterface"
                  label="Minimize Interface"
                  color="#a59a9a"
                  hide-details
                ></v-checkbox>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import { eventBus } from "../../main";

import { mapState } from "vuex";
export default {
  name: "LayerControls",
  data: () => ({
    readOnlyButtonValues: [],
    autoPlayCount: 0,
    autoPlayCycle: [
      [0, 2],
      [0, 0],
      [1, 1],
      [2, 2],
      [0, 0],
      [0, 1],
      [0, 2]
    ],
    autoPlay: false,
    show: true,
    showLayer: false,
    hasChangedLayer: false,
    //ticksLabels: ["Existing", "1 Year", "5 Year"]
    ticksLabels: ["Existing", "Future", "Under Construction"]
  }),
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      categoryLookupRaw: state => state.markers.categoryLookup,
      setup: state => state.filters.setup,
      colors: state => state.filters.colors,
      mapStyleExtraFilters: state => state.filters.mapStyleExtraFilters,

      mapboxStyleFilters: state => state.filters.mapboxStyleFilters,

      programRadioOptions: state => state.filters.programRadioOptions,

      regionSelectOptions: state => state.filters.regionSelectOptions,
      countrySelectOptions: state => state.filters.countrySelectOptions,
      awardSelectOptions: state => state.filters.awardSelectOptions
    }),
    categoryLookup() {
      return { ...this.categoryLookupRaw, ...this.mapStyleExtraFilters };
    },
    //categoryLookup() {
    //return { ...this.categoryLookupRaw, ...this.mapStyleExtraFilters };
    //},

    setup_bools() {
      return { ...this.setup.bools, ...this.mapStyleExtraFilters };
    },
    iconSize() {
      const size = {
        xs: "medium",
        sm: "medium",
        md: "large",
        lg: "large",
        xl: "large"
      }[this.$vuetify.breakpoint.name];
      if (size) {
        //do nothing;
      }
      return ""; //size ? { [size]: true } : {};
    },
    layers: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.layers;
      },
      set(value) {
        if (!this.hasChangedLayer) {
          window.mvAnalyticEvent("filter", "layerUpdated", "layerUpdated", 1);
          this.hasChangedLayer = true;
        }
        this.show = false;
        this.$store.dispatch("filters_controlsUpdateLayers", value);
        eventBus.$emit("nav_filterRun");
      }
    },
    mapboxLayers: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.mapboxLayers;
      },
      set(value) {
        /*
        if (!this.hasChangedLayer) {
          window.mvAnalyticEvent("filter", "mapboxLayerUpdated", "layerUpdated", 1);
          this.hasChangedLayer = true;
        }
        this.show = false;
        */
        this.$store.dispatch("filters_controlsUpdateMapboxLayers", value);
      }
    },
    range: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.range;
      },
      set(value) {
        if (value[0] > value[1]) {
          value = [2, 0];
        }

        if (!this.hasChangedLayer) {
          //window.mvAnalyticEvent("filter", "layerUpdated", "layerUpdated", 1);
          this.hasChangedLayer = true;
        }
        this.show = false;

        this.$store.dispatch("filters_controlsUpdateRange", value);
        eventBus.$emit("nav_filterRun");
      }
    },
    programRadio: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.programRadio;
      },
      set(value) {
        if (!this.hasChangedLayer) {
          window.mvAnalyticEvent("filter", "layerUpdated", "layerUpdated", 1);
          this.hasChangedLayer = true;
        }
        this.show = false;
        this.$store.dispatch("filters_controlsUpdateProgramRadio", value);
        eventBus.$emit("nav_filterRun");
      }
    },

    regionSelect: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.regionSelect;
      },
      set(value) {
        if (!this.hasChangedLayer) {
          window.mvAnalyticEvent("filter", "layerUpdated", "layerUpdated", 1);
          this.hasChangedLayer = true;
        }
        this.show = false;
        this.$store.dispatch("filters_controlsUpdate_regionSelect", value);
        eventBus.$emit("nav_filterRun");
      }
    },

    countrySelect: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.countrySelect;
      },
      set(value) {
        if (!this.hasChangedLayer) {
          window.mvAnalyticEvent("filter", "layerUpdated", "layerUpdated", 1);
          this.hasChangedLayer = true;
        }
        this.show = false;
        this.$store.dispatch("filters_controlsUpdate_countrySelect", value);
        eventBus.$emit("nav_filterRun");
      }
    },

    awardSelect: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.awardSelect;
      },
      set(value) {
        this.$store.dispatch("filters_resetLayers", true);

        if (!this.hasChangedLayer) {
          window.mvAnalyticEvent("filter", "layerUpdated", "layerUpdated", 1);
          this.hasChangedLayer = true;
        }
        this.show = false;
        this.$store.dispatch("filters_controlsUpdate_awardSelect", value);
        eventBus.$emit("nav_filterRun");
      }
    },
    flyToBbOnFilter: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.flyToBbOnFilter;
      },
      set(value) {
        this.$store.dispatch("filters_controlsUpdate_flyToBbOnFilter", value);
      }
    },
    showStatusLabels: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.showStatusLabels;
      },
      set(value) {
        this.$store.dispatch("filters_controlsUpdate_showStatusLabels", value);
      }
    },
    minimzeInterface: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.minimzeInterface;
      },
      set(value) {
        this.$store.dispatch("filters_controlsUpdate_minimzeInterface", value);
      }
    }
  },

  methods: {
    toggleRangeSlider(index) {
      let currentValue = this.range[0] <= index && index <= this.range[1];

      if (index == 0) {
        if (currentValue) {
          this.range = [1, this.range[1]];
        } else {
          this.range = [0, this.range[1]];
        }
      }

      if (index == 2) {
        if (currentValue) {
          this.range = [this.range[0], 1];
        } else {
          this.range = [this.range[0], 2];
        }
      }

      if (index == 1) {
        if (currentValue) {
          if (this.range[0] == 1 && this.range[1] == 1) {
            this.range = [2, 0];
          } else if (this.range[0] != 0) {
            this.range = [2, 2];
          } else {
            this.range = [0, 0];
          }
        } else {
          if (this.range[0] == 2 && this.range[1] == 0) {
            this.range = [1, 1];
          } else if (this.range[0] == 0) {
            this.range = [0, 1];
          } else {
            this.range = [1, 2];
          }
        }
      }
    },
    resetFilters() {
      this.$store.dispatch("filters_resetLayers", true);
    },
    remoteClose() {
      this.showLayer = false;
    },
    openAbout(key) {
      eventBus.$emit("loadLayerInfoDialog", key);
    },
    tickRangeSlider() {
      if (this.autoPlay === true) {
        this.autoPlayCount++;
        if (!this.autoPlayCycle[this.autoPlayCount]) {
          this.autoPlayCount = 0;
        }
        this.range = this.autoPlayCycle[this.autoPlayCount];
      }
    }
  },
  created() {
    eventBus.$on("remote-ui-layercontrol-close", this.remoteClose);

    setInterval(() => {
      this.tickRangeSlider();
    }, 2000);
  },
  beforeDestroy() {
    eventBus.$off("remote-ui-layercontrol-close", this.remoteClose);
  },

  watch: {
    autoPlay: function(newValue) {
      if (newValue === true) {
        this.autoPlayCount = 0;
        this.flyToBbOnFilter = false;
      } else {
        this.range = [0, 2];
        this.autoPlayCount = 0;
        //this.flyToBbOnFilter = true;
      }
    },
    flyToBbOnFilter: function(newValue) {
      if (newValue === true) {
        this.autoPlay = false;
      }
    },
    showLayer: function(newValue) {
      if (newValue === true) {
        window.mvAnalyticEvent("ui", "layerControlsOpen", "open", false);

        eventBus.$emit("remote-ui-legned-close");
      }
    },
    layers: function(newValue, oldValue) {
      let added = newValue.filter(layer => !oldValue.includes(layer));
      let removed = oldValue.filter(layer => !newValue.includes(layer));

      if (added.length) {
        added.forEach(layer => {
          window.mvAnalyticEvent("ui", "layerShow", layer, false);
        });
      }
      if (removed.length) {
        removed.forEach(layer => {
          window.mvAnalyticEvent("ui", "layerHide", layer, false);
        });
      }
    }
  }
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
}

.noDropShadow {
  box-shadow: none !important;
}

.toggleWrapper {
  margin-left: -8px;
  margin-right: -8px;
}

.fakeCheckboxNew.brand .v-btn {
  text-transform: none;
}

div
  .fakeCheckbox.theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  .v-btn.v-btn,
div .fakeCheckbox.theme--light.v-btn-toggle button.v-btn.v-btn,
.fakeCheckboxNew button.v-btn.v-btn {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.theme--light.v-btn--active::before {
  opacity: 0;
}

div .fakeCheckbox .theme--light:hover::before {
  opacity: 0.18 !important;
}

div .fakeCheckbox .theme--light.v-btn:hover::before {
  opacity: 0.08 !important;
}

div .fakeCheckbox div.infoBox {
  width: 100%;
  height: 0;

  padding: 0;
  overflow: visible;
  z-index: 1;
}

div .fakeCheckbox div.infoBox div.infoBoxAct {
  width: 45px;
  height: 29px;
  padding: 0;
  float: right;
  margin-top: -33px;
  margin-right: 4px;
  overflow: visible;
}

.layerControlWrapper {
  transition: all 0.5s;
  top: 72px;
  margin-left: auto;
  position: absolute;
  /* border-radius: 2px; */
  /* width: 500px; */
  right: 6px;
}

.mobileSet {
}
.layerWrapper {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d8d8d8;
  position: absolute;
  right: 0;
  top: 0;
}

.fakeCheckbox button {
  height: auto !important;
}
.fakeCheckbox button {
  border-radius: 0 !important;
  justify-content: left;
  font-weight: 400;
}
.fakeCheckbox div {
  width: 300px;
  max-width: 100%;
  margin-right: 0;
  white-space: none;
  white-space: normal;

  text-align: left;
  height: auto;

  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
}

.fakeCheckbox.fakeCheckboxNew div {
  width: auto;
}
.fakeCheckbox.fakeCheckboxMapboxLayers div {
  width: auto;
}

.fakeCheckbox.fakeCheckboxMapboxLayers button,
.fakeCheckbox.fakeCheckboxMapboxLayers button label {
  width: auto;
  overflow: hidden;
  text-transform: unset;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 400 !important;

  color: rgba(0, 0, 0, 0.6) !important;
}

@media only screen and (max-width: 959px) {
  .layerControlWrapper {
    top: 62px;
  }

  .fakeCheckbox button {
    border-radius: 0 !important;
    justify-content: left;
  }
  .fakeCheckbox div {
    padding-top: 8px;
    padding-bottom: 7px;
    padding-left: 2px;
  }

  .fakeCheckbox,
  .fakeCheckbox div,
  .layerControlWrapper {
    max-width: calc(100vw - 100px) !important;
  }
}

@media only screen and (min-width: 960px) {
  .fakeCheckbox button {
    min-height: 26px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}
</style>
