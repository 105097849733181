<template>
  <v-app
    :class="{
      currentCountryNotEnglish: currentCountryNotEnglish,
      'my-app': true,
      isApple: isIOS,
      isComputer: !$vuetify.breakpoint.mdAndDown,
      isTabletOrComputer: !$vuetify.breakpoint.smAndDown,
      isSmallComputer: !$vuetify.breakpoint.smAndDown,
      isSmallPcOrTabletOrMobile: $vuetify.breakpoint.mdAndDown,
      isTabletOrMobile: $vuetify.breakpoint.mdAndDown,
      isTablet: $vuetify.breakpoint.mdOnly,
      minimzeInterface: minimzeInterface,
      hideDetails: currentZoom < 2.5,

      showDetails: currentZoom >= 2.5
    }"
  >
    <Layout v-if="ready" />
    <AboutModal :isReady="ready" :showButton="false" v-if="true" class="mr-8" />
  </v-app>
</template>

<script>
import Layout from "./Layout.vue";
import { mapState } from "vuex";
import AboutModal from "./components/info/AboutModal.vue";

export default {
  name: "App",
  async mounted() {
    this.$store.dispatch("wpData_initData", { vm: this });
  },
  components: {
    Layout,
    AboutModal
  },

  data: () => ({
    ready: false
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      minimzeInterface: state => state.filters.minimzeInterface,
      currentZoom: state => state.navigation.currentZoom,
      wpdata: state => state.wpdata.wpdata,
      currentCountryNotEnglish: state =>
        state.translator.currentCountryNotEnglish
    }),
    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    wpdata: function(newValue) {
      if (newValue?.categoryOrder.length) {
        this.$store.dispatch("global_updateMaptivateSetupObject", newValue);
        this.$store.dispatch("markers_updateData", newValue);
        this.$store.dispatch("filters_updateData", newValue);

        this.ready = true;
      }
    }
  },

  created() {
    window.mvAnalyticEvent = function(category, action, label, value) {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: !isNaN(value) ? value : 1
      });
      //window.gtag("event", "t_" + action + "_" + label);
    };

    document.ondblclick = function(e) {
      e.preventDefault();
    };

    document?.addEventListener("gesturestart", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document?.addEventListener("gesturechange", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document?.addEventListener("gestureend", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window?.addEventListener("resize", documentHeight);
    window?.addEventListener("orientationchange", documentHeight);
    screen?.orientation?.addEventListener("change", documentHeight);

    documentHeight();
  }
};
</script>
<style lang="scss">
:root {
  --doc-height: 100%;
}
body {
  //touch-action: manipulation;
  touch-action: pan-y, pan-x;
}
.mapboxgl-ctrl-top-left {
  margin-top: -2px;
}

.mapboxgl-ctrl-top-left mapbox-search-box input {
  height: 40px;
}

@media only screen and (max-width: 960px) {
  .mapboxgl-ctrl-top-left mapbox-search-box > div {
    margin-top: -4px !important;
    max-width: 200px;
  }
}

@media only screen and (max-width: 640px) {
  .mapboxgl-ctrl-top-left mapbox-search-box > div {
    max-width: 150px;
  }
}

.mapboxgl-ctrl-bottom-right
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  right: 68px;
}

.secondrow,
.thirdrow {
  .v-text-field__details,
  .v-messages {
    display: none;
  }
}

.floatingLayerControlButtons .v-select__slot label.v-label,
.hasWhiteBackground {
  background: #ffffff82;
  border-radius: 5px;
  padding: 0 5px;
}

.floatingLayerControlButtons .v-input__slider,
.floatingLayerControlButtons .fixedHight {
  height: 40px;
}
.floatingLayerControlButtons .v-input__slider .v-slider {
  margin-top: -5px;
}

.hideDetails .mvStatsWrapper {
  opacity: 0 !important;
}

.fadeDetails .mvStatsWrapper {
  opacity: 0.7 !important;
}

.showDetails .mvStatsWrapper {
  opacity: 1 !important;
}

.mvStatsWrapper {
  transition: opacity 0.5s;
  height: 0;
  width: 0;
  overflow: visible;

  .mvStats {
    float: left;
    height: auto;
    width: auto;
    background: #b7b7b770;
    padding: 10px;
    color: white;
    font-size: medium;
    text-shadow: #000 1px 0 4px;
    border-radius: 4px;

    &.rightFloat {
      float: right;
    }

    font.statHeading,
    .mainStatLabel {
      font-weight: bold;
    }
  }
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl.mapboxgl-ctrl-attrib a {
  color: #575757 !important;
}

.minimzeInterface mapbox-search-box {
  display: none;
}
</style>
