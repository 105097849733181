//import _, { initial } from "lodash";

import { mapState } from "vuex";
import { eventBus } from "../../../main";

import localAs_Markers from "./../services/localAs_Markers.js";

let deDubeFeatures = features => {
  let letFound = {};

  try {
    features = features.filter(feature => {
      if (!letFound[feature.id]) {
        letFound[feature.id] = true;
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    //return false;
  }
  return features;
};

export default {
  data: () => ({
    localAsYouLinkeIt_dataSource: "localAsYouLinkeIt_data",
    localAsYouLinkeIt_dataSourceSetup: false,
    geoJson_local_as_you_like_it_filtered: {
      data: { type: "FeatureCollection", features: [] },
      type: "geojson"
    },
    geoJson_local_as_you_like_it_features: []
  }),
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      sidebarSite: state => state.navigation.sidebarSite,

      markerList: state => state.markers.markerList,

      geoJson_local_as_you_like_it: state =>
        state.markers.geoJson_local_as_you_like_it
    }),
    sites() {
      return this.markerList.data.features;
    },
    properties() {
      try {
        if (
          this.sites &&
          !isNaN(this.sidebarSite) &&
          this.sites[this.sidebarSite] &&
          this.sites[this.sidebarSite].properties
        ) {
          return this.sites[this.sidebarSite].properties;
        } else {
          //return null;
          throw { error: "error" };
        }
      } catch (error) {
        return null;
      }
    }
  },
  methods: {
    localAs_click(event) {
      try {
        event.features = deDubeFeatures(event?.features);

        let coordinates = event.features[0].geometry.coordinates.slice();
        let propertiesEvent = event.features[0].properties;

        let { post_id } = propertiesEvent;

        let properties = null;

        try {
          let rows = this.geoJson_local_as_you_like_it?.data?.features?.filter(
            row => row?.properties?.post_id == post_id
          );

          if (rows[0]) {
            properties = rows[0].properties;
          }
        } catch (error) {
          //do nothing;
        }

        this.clearAllPopups();

        if (coordinates.length && properties && properties.post_id) {
          this.createLocalAsPopup(coordinates, properties);
        }
      } catch (error) {
        console.error({ error });
      }
    },

    localAs_createData() {
      try {
        this.map.addSource(this.localAsYouLinkeIt_dataSource, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: []
          }
        });

        localAs_Markers.createLocalAsLayer({
          parentThis: this,
          map: this.map,
          dataIdName: "localAsYouLinkeIt_data",
          layerIdName: "localAsYouLinkeIt_layer",
          appSettings: this.appSettings
        });

        try {
          this.map.on("click", "localAsYouLinkeIt_layer", event => {
            this.localAs_click(event);
          });
        } catch (error) {
          //do nothing;
        }

        this.localAsYouLinkeIt_dataSourceSetup = true;
      } catch (error) {
        //do nothingk
        console.error({ error });
      }
    },

    localAs_updateData() {
      if (!this.localAsYouLinkeIt_dataSourceSetup) {
        this.localAs_createData();
      }

      try {
        this.map.getSource(this.localAsYouLinkeIt_dataSource).setData({
          type: "FeatureCollection",
          features: [...this.geoJson_local_as_you_like_it_features]
        });
      } catch (error) {
        console.error({ error });
      }
    },
    localAs_filterMarkers() {
      let markers = this?.geoJson_local_as_you_like_it?.data?.features?.filter(
        row => {
          return (
            row?.properties?.M_LINKED_POST_linkedMarker ===
            this?.properties?.post_id
          );
        }
      );
      this.geoJson_local_as_you_like_it_features = markers?.length
        ? markers
        : [];

      if (!isNaN(this.sidebarSite) && false !== this.sidebarSite) {
        this.localAs_updateData();
      } else {
        //do nothing
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {
    eventBus.$on("nav_map_localAsFlyTo", site => {
      let { Longitude, Latitude } = site;
      let center = [Latitude, Longitude];
      let zoom = this.map.getZoom();
      this.map.flyTo({ center, zoom: zoom > 13 ? zoom : 13 });

      this.clearAllPopups({ isLocalAs: true });

      if (center.length && site && site.post_id) {
        this.createLocalAsPopup(center, site);
      }
    });
  },
  watch: {
    sidebarSite: function() {
      this.localAs_filterMarkers();
    }
  }
};
