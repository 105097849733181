import _ from "lodash";
import { mapState } from "vuex";
const turf = require("@turf/turf");

export default {
  data: () => ({
    //layerState: false
  }),

  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings,
      mapStyleExtraFilters: state => state.filters.mapStyleExtraFilters,
      flyToBbOnFilter: state => state.filters.flyToBbOnFilter,
      showStatusLabels: state => state.filters.showStatusLabels,

      allTaxonomiesLookup: state => state.markers.allTaxonomiesLookup,

      currentCountryNotEnglish: state =>
        state.translator.currentCountryNotEnglish,
      translationLanguageCode: state =>
        state.translator.translationLanguageCode,
      translatedList: state => state.translator.translatedList,

      regionSelectOptions: state => state.filters.regionSelectOptions
    }),
    fields() {
      let defaultTooltipLabelField = null;
      defaultTooltipLabelField = this?.appSettings?.defaultTooltipLabelField;
      let places = this?.appSettings?.layerDefaults?.places?.labelField;
      let signage = this?.appSettings?.layerDefaults?.signage?.labelField;

      return {
        places: {
          label: places,
          tooltip: defaultTooltipLabelField ? defaultTooltipLabelField : places
        },
        signage: {
          label: signage,
          tooltip: defaultTooltipLabelField ? defaultTooltipLabelField : signage
        }
      };
    }
  },

  methods: {
    translateGeoJsonLables_debounced: _.debounce(function() {
      this.translateGeoJsonLables();
    }, 1500),

    translateGeoJsonLables() {
      try {
        let dataSouces = ["signage", "places"];
        dataSouces.forEach(source => {
          var labelField = null,
            tooltipField = null;

          if (this.fields && this.fields[source]) {
            if (this.fields[source].label) {
              labelField = this.fields[source].label;
            }
            if (this.fields[source].tooltip) {
              tooltipField = this.fields[source].tooltip;
            }
            if (labelField && tooltipField && labelField == tooltipField) {
              tooltipField = null;
            }
          }

          let thisSourceData = this.map.getSource(source)._data;

          thisSourceData.features.forEach(feature => {
            var search = [labelField, tooltipField];

            search.forEach(fieldName => {
              if (fieldName) {
                if (feature.properties[fieldName]) {
                  let currentValue = feature.properties[fieldName];

                  //make backup of english version;

                  if (!feature.properties.englishClone) {
                    feature.properties.englishClone = {};
                  }
                  if (!feature.properties.englishClone[fieldName]) {
                    feature.properties.englishClone[fieldName] =
                      feature.properties[fieldName];
                  }
                  //make backup of english version;

                  if (
                    feature.properties?.englishClone &&
                    feature.properties?.englishClone[fieldName]
                  ) {
                    let englishWord =
                      feature.properties?.englishClone[fieldName];

                    if (englishWord && englishWord !== currentValue) {
                      //if value set to english;
                      feature.properties[fieldName] = englishWord;
                      currentValue = feature.properties[fieldName];
                    }

                    if (
                      englishWord &&
                      this.currentCountryNotEnglish &&
                      this.translatedList &&
                      this.translationLanguageCode &&
                      this.translatedList[this.translationLanguageCode] &&
                      this.translatedList[this.translationLanguageCode][
                        englishWord
                      ] &&
                      this.translatedList[this.translationLanguageCode][
                        englishWord
                      ] != currentValue
                    ) {
                      //if not english, and there is a lookup, but current value is not the lookup;

                      feature.properties[fieldName] = this.translatedList[
                        this.translationLanguageCode
                      ][englishWord];
                    }
                  }
                }
              }
            });
          });

          this.map.getSource(source).setData(thisSourceData);
        });
      } catch (error) {
        //do nothing;
      }
    },

    updateMapboxLayers() {
      try {
        this.mapboxStyleFilters.forEach(row => {
          let { id, layerIdPrefix } = row;

          let value = this.mapboxLayers.includes(id) ? "visible" : "none";

          // Loop over all the layers in the style
          const style = this.map.getStyle();
          style.layers.forEach(layer => {
            let mapboxLayerId = layer.id;

            if (mapboxLayerId.includes(layerIdPrefix)) {
              const layerAct = this.map.getLayer(layer.id);

              if (!layerAct) {
                console.error("layer missing", {
                  mapboxLayerId,
                  layerIdPrefix
                });
              } else {
                this.map.setLayoutProperty(layer.id, "visibility", value);
              }
            }
          });
        });
      } catch (error) {
        console.error({ error });
      }
    },

    updatefilters(filtersInput) {
      let filters = this.layers;

      if (filtersInput) {
        filters = filtersInput;
      }
      let range = this.range;
      let programRadio = this.programRadio;

      let regionSelect = this.regionSelect;
      let countrySelect = this.countrySelect;
      let awardSelect = this.awardSelect;

      if (range && range.length) {
        range = range.map(row => {
          try {
            if (row === 0 || row === 1) {
              //row =  row;
            } else if (row > 1) {
              row = (row - 1) * 5;
            } else {
              //return row;
            }
          } catch (error) {
            //do nothing;
          }

          return row;
        });
        // years are //0:current, 1:1year, 2:5years and so on.....
        //convert [0,1] to [0, 1] to say between now and 1 years;
        //convert [0,2] to [0, 5] to say between now and 5 years;
        //convert [3,5] to [10, 20] to say between 10 and 20 years;

        //do nothing;
      }

      let mapStyleKeys = _.keys(this.mapStyleExtraFilters);
      let mapStyleFilters = filters.filter(row => mapStyleKeys.includes(row));
      filters = filters.filter(row => !mapStyleKeys.includes(row));
      mapStyleKeys.forEach(layer => {
        let layerId = layer.replace("basemapStyle_", "");
        let show = false;
        let hasLinkedLayer = this.mapStyleExtraFilters[layer]
          ?.linkedToMakerLayer;

        if (mapStyleFilters.includes(layer)) {
          show = true;
        }
        if (filters.includes(hasLinkedLayer)) {
          show = true;
        }
        if (show === true) {
          //show layer;
          this.map.setLayoutProperty(layerId, "visibility", "visible");
        } else {
          //hide layer;
          this.map.setLayoutProperty(layerId, "visibility", "none");
        }
      });

      //markerLayerFilter;
      let dataSouces = _.values(this.filterableDataSouceIds);
      dataSouces.forEach(source => {
        let thisSourceData = this.map.getSource(source)._data;
        thisSourceData.features.forEach(feature => {
          if (filters.includes(feature.properties.section_slug)) {
            feature.properties.hide = false;
          } else {
            feature.properties.hide = true;
          }

          //do more custom filtering;

          //this is to check a custom filter on state;
          if (!feature.properties.hide && source == "places") {
            if (regionSelect && regionSelect !== "all")
              if (
                feature?.properties?.postTaxonomy?.taxSlugs?.state?.length &&
                feature?.properties?.postTaxonomy?.taxSlugs?.state.includes(
                  regionSelect
                )
              ) {
                //is a match
              } else {
                feature.properties.hide = true;
              }
          }

          //this is to check a custom filter on country;
          if (!feature.properties.hide && source == "places") {
            if (countrySelect && countrySelect !== "all")
              if (
                feature?.properties?.postTaxonomy?.taxSlugs?.country?.length &&
                feature?.properties?.postTaxonomy?.taxSlugs?.country.includes(
                  countrySelect
                )
              ) {
                //is a match
              } else {
                feature.properties.hide = true;
              }
          }

          //this is to check a custom filter on award;
          if (!feature.properties.hide && source == "places") {
            if (awardSelect && awardSelect !== "all")
              if (
                feature?.properties?.postTaxonomy?.taxSlugs?.award?.length &&
                feature?.properties?.postTaxonomy?.taxSlugs?.award.includes(
                  awardSelect
                )
              ) {
                //is a match
              } else {
                feature.properties.hide = true;
              }
          }

          //this is to check a custom filter on hotel programs, radio buttons filtiner hotel_programs taxonomy;
          if (!feature.properties.hide && source == "places") {
            if (programRadio && programRadio !== "all")
              if (
                feature?.properties?.postTaxonomy?.taxSlugs?.hotel_programs
                  ?.length &&
                feature?.properties?.postTaxonomy?.taxSlugs?.hotel_programs.includes(
                  programRadio
                )
              ) {
                //is a match
              } else {
                feature.properties.hide = true;
              }
          }
          //this is to filter based on year range
          if (
            !feature.properties.hide &&
            source == "places" &&
            range &&
            !isNaN(range[0]) &&
            !isNaN(range[1])
          ) {
            //has range;
            if (
              !isNaN(
                feature?.properties?.structuredData?.value?.M_RN_status?.value
              )
            ) {
              let value =
                feature?.properties?.structuredData?.value?.M_RN_status?.value;
              if (range[0] <= value && value <= range[1]) {
                //("show");
              } else {
                //("hide");
                feature.properties.hide = true;
              }
            }
          }

          //end of custom extra filters

          //row

          if (feature?.properties?.section_slug == "conference-locations") {
            feature.properties.hide = false;
          }

          if (!feature.properties.hide) {
            if (!feature.properties.isGeomLinePolyLayer) {
              feature.geometry.coordinates = [
                feature.properties.Latitude,
                feature.properties.Longitude
              ];
            }
          } else {
            if (!feature.properties.isGeomLinePolyLayer) {
              feature.geometry.coordinates = [0, 0];
            }
          }

          if (
            feature.properties.isGeomLinePolyLayer &&
            feature.properties.section_slug == "Art_zoom1"
          ) {
            feature.properties.hide = false;
          }

          if (feature.properties.isGeomLinePolyLayer) {
            //do nothing;
          }
        });

        if (source == "places") {
          let post_ids = thisSourceData.features
            .filter(row => row?.properties?.hide != true)
            .map(row => row.properties.post_id);
          this.$store.dispatch("filters_set_visible_post_ids", post_ids);
        }

        this.map.getSource(source).setData(thisSourceData);
      });

      this.recalcClosestPoint();

      this.recalcRegionStats();
      this.recalcBrandStats();

      if (this.flyToBbOnFilter === true && this.mapIsReadyAndInit === true) {
        setTimeout(() => {
          this.focusOnSites();
        }, 50);
      }
    },

    focusOnSites: _.debounce(function() {
      let thisSourceData = this.map.getSource("places")._data;

      thisSourceData = JSON.parse(JSON.stringify(thisSourceData));

      thisSourceData.features = thisSourceData?.features?.filter(
        row => row?.properties?.hide !== true
      );

      thisSourceData.features = thisSourceData?.features?.filter(
        row =>
          row?.properties?.section_slug !== "conference-locations" &&
          row?.properties?.section_slug !== "construction"
      );

      if (thisSourceData.features.length) {
        let bbox = turf.bbox(thisSourceData);

        const padding = this.$vuetify.breakpoint.mdAndUp
          ? { left: 100, right: 500, top: 100, bottom: 100 }
          : { left: 30, right: 30, top: 30, bottom: 30 };

        this.map.fitBounds(bbox, { padding, maxZoom: 12 });
      }
    }, 250),

    calculateClosestPoints(featureCollection, options) {
      let { defaultSet, set, setTitle, note } = options;
      let points = featureCollection.features;

      points = points?.filter(
        row =>
          row?.properties?.section_slug !== "conference-locations" &&
          row?.properties?.section_slug !== "construction"
      );

      //reset previous calcs and init point
      points.forEach((feature /*, index*/) => {
        try {
          if (!feature.properties.distanceCalc) {
            feature.properties.distanceCalc = {};
          }
        } catch (error) {
          //do nohting;
        }
        try {
          if (
            feature?.properties?.distanceCalc &&
            feature.properties.distanceCalc[set]
          ) {
            delete feature.properties.distanceCalc[set];
          }
        } catch (error) {
          //do nohting;
        }
        //feature.properties.currentRowIndex = index;
      });

      //filter out hidden points;
      let pointsFilteredTemp = points.filter(row => {
        return !row?.properties?.hide || defaultSet;
      });
      //clone
      let pointsFiltered = JSON.parse(JSON.stringify(pointsFilteredTemp));

      if (defaultSet == true) {
        //reset locations
        pointsFiltered.forEach(feature => {
          feature.geometry.coordinates = [
            feature.properties.Latitude,
            feature.properties.Longitude
          ];
        });
      }

      let run = [pointsFiltered];

      if (set == "brand" || set == "brandInital") {
        let markersection_keys = [];

        try {
          markersection_keys = this?.allTaxonomiesLookup?.markers?.markersection
            ?.keys;
        } catch (error) {
          //do nothing;
        }

        run = [];

        markersection_keys.forEach(sectionKey => {
          let brandSet = pointsFiltered.filter(feature => {
            return feature?.properties?.list_section_slug === sectionKey;
          });

          if (brandSet && brandSet.length) {
            run.push(brandSet);
          }
        });
      }

      run.forEach(pointsFiltered => {
        if (pointsFiltered.length > 1) {
          pointsFiltered.forEach((feature, index) => {
            let ownProps = feature.properties;
            //let ownName = ownProps.Point_Name;
            let ownPostId = ownProps.post_id;

            let closestPoint = null;
            let minDistance = 999999999999999999999999;

            pointsFiltered.forEach((otherPoint, otherIndex) => {
              if (index !== otherIndex) {
                const distance = turf.distance(feature, otherPoint);

                if (distance < minDistance && distance !== 0) {
                  minDistance = distance;
                  closestPoint = JSON.parse(JSON.stringify(otherPoint));
                }
              }
            });

            try {
              minDistance = Math.round(minDistance * 100) / 100;

              if (minDistance > 3) {
                minDistance = Math.round(minDistance * 1) / 1;
              }
            } catch (error) {
              //do nothing!!;
            }

            try {
              let {
                //currentRowIndex,
                index,
                post_id,
                Point_Name,
                slug,
                list_section_slug,
                list_section_title
              } = closestPoint.properties;

              let details = {
                properties: {
                  index,
                  post_id,
                  Point_Name,
                  slug,
                  list_section_slug,
                  list_section_title
                },
                setTitle,
                note,
                set,
                distance: minDistance
              };

              details = JSON.parse(JSON.stringify(details));

              //feature.properties.distanceCalc[set] = details;
              try {
                //points[currentRowIndex].properties.distanceCalc[set] = details;

                featureCollection.features.forEach(row => {
                  if (row?.properties?.post_id === ownPostId) {
                    row.properties.distanceCalc[set] = details;
                  }
                });
              } catch (error) {
                //do nothing;
              }
            } catch (error) {
              // do nothing
            }
          });
        }
      });
      //clean up;
      /*points.forEach(feature => {
        delete feature.properties.currentRowIndex;
      });
*/
      return featureCollection;
    },

    recalcClosestPoint() {
      //markerLayerFilter;
      let dataSouces = _.values(this.filterableDataSouceIds);
      dataSouces.forEach(source => {
        if (source === "places") {
          let thisSourceData = this.map.getSource(source)._data;

          thisSourceData = this.calculateClosestPoints(thisSourceData, {
            defaultSet: false,
            set: "any",
            setTitle: "Any"
          });

          thisSourceData = this.calculateClosestPoints(thisSourceData, {
            defaultSet: true,
            set: "anyInital",
            setTitle: "Any (inc. hidden)",
            note: "(inc. hidden)"
          });

          thisSourceData = this.calculateClosestPoints(thisSourceData, {
            defaultSet: false,
            set: "brand",
            setTitle: "Same Brand"
          });

          thisSourceData = this.calculateClosestPoints(thisSourceData, {
            defaultSet: true,
            set: "brandInital",
            setTitle: "Same Brand (inc. hidden)",

            note: "(inc. hidden)"
          });

          thisSourceData.features.forEach(feature => {
            if (feature) {
              //do notnhing
            }
          });

          let distanceCalculationLookup = {};
          thisSourceData.features.forEach(feature => {
            let { properties } = feature;
            let { distanceCalc, index } = properties;
            distanceCalculationLookup[index] = distanceCalc;
          });

          this.$store.dispatch(
            "marker_UpdateDistanceCalculations",
            distanceCalculationLookup
          );

          this.map.getSource(source).setData(thisSourceData);
        }
      });
    }
  },
  created() {},
  destroyed() {},
  mounted() {},

  watch: {
    flyToBbOnFilter(value) {
      if (value && value === true) {
        this.focusOnSites();
      }
    },

    showStatusLabels() {
      this.recalcBrandStats();
      this.recalcRegionStats();
    },

    currentCountryNotEnglish(value) {
      if (!value) {
        this.translateGeoJsonLables();
      }
    },
    translationLanguageCode() {
      this.translateGeoJsonLables();
      this.translateGeoJsonLables_debounced();
      setTimeout(() => {
        this.translateGeoJsonLables_debounced();
      }, 700);
    },
    translatedList() {
      this.translateGeoJsonLables_debounced();

      setTimeout(() => {
        this.translateGeoJsonLables_debounced();
      }, 700);
    }
  }
};
