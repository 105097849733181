const getters = {};
const mutations = {
  SET_maptivateSetupObject(state, newValue) {
    state.maptivateSetupObject = newValue;
  }
};

const actions = {
  async global_updateMaptivateSetupObject({ commit }, wpData) {
    commit("SET_maptivateSetupObject", wpData.maptivateSetupObject);
  }
};
const state = {
  maptivateSetupObject: {},
  appSettings: {
    map: {
      accessToken:
        "pk.eyJ1IjoiYXNjb3R0bWFwIiwiYSI6ImNseWNoemNiMTFsb3kycG9mNGxjN3pjM2cifQ.eXypVA_jFvn-JC1U3rESbw", //maptivate key;
      //"pk.eyJ1IjoibWFwdGl2YXRlc2VydmljZWFjY291bnQiLCJhIjoiY2xyNXZ0d3B0MXpjbTJscWJ3cWhkbGlvMiJ9.yMuBfMksI3dAad1PytrHyg", //maptivate service;
      options: {
        style: "mapbox://styles/ascottmap/clyp657jv01ct01pt0otv6n79",
        center: [144.0609714391107, -29.10053930951002],
        maxZoom: 22,
        minZoom: 0,
        pitch: 0,
        bearing: 0,
        zoom: 0
      },
      boundsx: [
        [109, -46.964], // Southwest coordinates
        [183.6, -9.87] // Northeast coordinates
      ],
      defaultSetup: {
        center: [144.0609714391107, -29.10053930951002],
        zoom: 16,
        zoomTab: 16,
        zoomMob: 16,
        pitch: 15,
        bearing: 0
        //bearing: 0
      },
      flyToBounds: [
        [109, -46.964], // Southwest coordinates
        [183.6, -9.87] // Northeast coordinates
      ],
      disableRotationAndBearing: false
    },

    wpEditSite:
      "https://cms01.maptivateapp.com.au/theascott/wp-admin/post.php?post=",

    onMoreInfoLoadOnMobileExpanText: true,

    pointsHaveDirections: true,
    markerIsPointer: false,
    showSiteNumbers: false,
    showSiteNumbers_Labels: true,
    showSiteNumbers_ClusterLabels: false,
    showQrCode: false,
    minZoom: { main: 1, secondary: 1, points: 1 },

    //nonClusteredDataSouces: [],
    //clusteredDataSouces: ["places", "signage"],

    useMapClustering: true,

    layersUnder: {
      art: null, //"melbzoo-labels (1)",
      places: null, //"melbzoo-labels (1)",
      signage: null, //"melbzoo-labels (1)",
      lines: false,
      fill: false,
      arrows: false
    },
    defaultTooltipLabelField: "mv_tooltip",

    markerImageAssetOrderPriority: {
      //list by section slug name,
      artwork_section: {
        catTaxonomy: "artwork_section",
        name: "artwork",
        behaviour: "normal", //normal, cluster,
        skip: [],
        priority: [
          "artwork_zoom1",
          "animals-hero",
          "buildings-hero",
          "north-arrow"
        ],
        delay: []
      },
      signage_section: {
        catTaxonomy: "signage_section",
        name: "signage",
        behaviour: "normal", //normal, cluster,
        skip: [""],
        priority: [""],
        delay: ["sensory"]
      },
      markersection: {
        catTaxonomy: "markersection",
        name: "marker",
        behaviour: "cluster"
        //load: ["fighting_extinction_species"]
      }
    },
    layerDefaults: {
      signage: {
        labelField: "mv_label",

        scaling: {
          minZoom: 8,
          opacityMinZoom: 5,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 5,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 0.7,

          opacitySymbolMinZoom: 1,
          opacitySymbolMaxZoom: 2
        },

        haveToolTip: true,
        showLabels: false,
        styles: {
          unclustered_paint: { "circle-color": "#00dcff" },
          clustered_paint: { "circle-color": "#c08e60" }
        },
        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: { cluster: true, clusterMaxZoom: 1, clusterRadius: 30 },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      },
      places: {
        labelField: "mv_label",

        useCircle: true,
        scaling: {
          minZoom: 0,
          opacityMinZoom: 4,
          opacityMaxZoom: 7,

          clusterOpacityMinZoom: 4,
          clusterOpacityMaxZoom: 7,
          unclusterScale: 1,

          opacitySymbolMinZoom: 10,
          opacitySymbolMaxZoom: 12
        },
        hasPopup: true,
        showLabels: true,
        hoverState: true,
        haveToolTip: true,
        styles: {
          //unclustered_paint: { "circle-color": "#ff0000" },
          //clustered_paint: { "circle-color": "#354d64" },

          unclustered_icon_size: {
            stops: [
              [11, 0.1],
              [12, 0.2],
              [14, 0.5],
              [15.5, 1],

              [18, 1.6]
            ]
          },

          unclustered_icon_size_symbols: {
            stops: [
              [11, 0.15],
              [12, 0.3],
              [14, 1],
              [15.5, 2],

              [18, 2.6]
            ]
          },

          unclustered_radius: {
            stops: [
              [2, 3],
              [4, 5],
              [7, 7],

              [14, 10],
              [17, 15]
            ]
          },

          clustered_radius: {
            stops: [
              [16, 10],
              [17, 20]
            ]
          }
        },

        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterLabel: false,
        clusterSetup: {
          cluster: false,
          clusterMaxZoom: 18,
          clusterRadius: 30,

          clusterProperties: {}
        },
        filter: {
          unclustered_layer: [
            "all",
            ["!", ["has", "point_count"]],
            ["!", ["==", ["get", "hide"], true]]
          ],

          clustered_layer: [
            "all",
            [["has", "point_count"]],
            ["!", ["==", ["get", "hide"], true]]
          ]

          /*
          unclustered_layer_symbol: [
            "all",
            ["!has", "point_count"],
            ["==", "render_icons", true]
          ],
*/
        }
      }
    },

    /*categoryLookup: {
      History: {
        icon: "history_marker",
        full: "history_full",
        logo: "history_icon",
        title: "Our Stories",
        description: "",

        color: "#626f60",
        padding: "15px"
      },

      "Prospective Families": {
        icon: "prospective_marker",
        full: "prospective_families_full",
        logo: "prospective_icon",
        title: "Our School",
        description: "",
        color: "#8e3839",
        padding: "15px"
      },

      Wayfinding: {
        icon: "wayfinding_marker",
        full: "wayfinding_full",
        logo: "wayfinding_icon",
        title: "Our Spaces",
        description: "",
        color: "#e0c381",
        padding: "6px"
      }
    },
*/
    socialIcon: {
      instagram: {
        icon: "mdi-instagram",
        color: "red lighten-3"
      },
      facebook: {
        icon: "mdi-facebook",
        color: "indigo"
      },
      twitter: {
        icon: "mdi-twitter",
        color: "#26c6da"
      },
      youtube: {
        icon: "mdi-youtube",
        color: "red"
      },
      tiktok: {
        icon: "mdi-alarm-multiple ",
        color: "black"
      },
      linkedin: {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      pinterest: {
        icon: "mdi-pinterest",
        color: "pink"
      },
      generic: {
        icon: "mdi-link-variant",
        color: "green"
      }
    },

    siteListSettings: {
      itemUseCircle: true,
      sectionUseCircle: true
    },

    validArSites: {
      "14": true,
      "23": true,
      "28B": true
    },
    zoomToOnSelect: 13,

    allowSkipWelcomeDialog: false,

    autoOpenMoreInfo: true,
    drawWidth: 450,
    mobDrawWidth: 300,

    popupButtonWidth: "269px",
    drawButtonWidth: "435px",

    mediaButtonColor: "primary", //a6caa6
    filterLegendButtonColor: "primary", //a6caa6

    appButtonColor: "black",
    dialogActionButtonColor: "black",
    logoTextColor: "primary",

    filterButtonColor: "tabs",
    mainMenuBarColor: "primary",
    menuBarColor: "tabs",

    audioPlayColor: "white",
    headingTextColor: "font-weight-regular text--darken-1",
    styles: {
      //oldMarkerColours: ["#003953", "#607d8b", "#f28cb1", "#d8db76"],
      markerColours: ["#582110", "#f4afcf", "#582110", "#961a1d"],
      //oldmarkerText: "#f6e9d8",
      markerTextLabel: "black",
      markerText: "white",
      markerTextBg: "white"
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
