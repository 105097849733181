const getters = {};

const imageLog = [];

const mutations = {
  SET_markerList(state, newValue) {
    state.markerList = newValue;
  },
  SET_geoJson_artwork(state, newValue) {
    state.geoJson_artwork = newValue;
  },
  SET_geoJson_signage(state, newValue) {
    state.geoJson_signage = newValue;
  },
  SET_geoJson_local_as_you_like_it(state, newValue) {
    state.geoJson_local_as_you_like_it = newValue;
  },
  SET_markerImages(state, newValue) {
    state.markerImages = newValue;
  },
  SET_sections(state, newValue) {
    state.sections = newValue;
  },
  SET_categoryLookup(state, newValue) {
    state.categoryLookup = newValue;
  },
  SET_stackedMarkers(state, newValue) {
    state.stackedMarkers = newValue;
  },
  SET_allTaxonomiesLookup(state, newValue) {
    state.allTaxonomiesLookup = newValue;
  },
  SET_markersScaleOpacityClass(state, newValue) {
    state.markersScaleOpacityClass = newValue;
  },
  SET_distanceCalculations(state, newValue) {
    state.distanceCalculations = newValue;
  }
};

const actions = {
  async marker_UpdateDistanceCalculations({ commit }, value) {
    commit("SET_distanceCalculations", value);
  },
  async marker_SetmarkersScaleOpacityClass({ commit }, value) {
    commit("SET_markersScaleOpacityClass", value);
  },
  async markers_updateData({ commit }, wpData) {
    wpData.geoJson_main_marker.features.forEach((row, index) => {
      row.properties.index = index;
    });

    if (wpData?.geoJson_main_marker?.features.length) {
      commit("SET_markerList", {
        type: "geojson",
        data: wpData.geoJson_main_marker
      });
    }

    if (wpData?.geoJson_artwork?.features.length) {
      commit("SET_geoJson_artwork", {
        type: "geojson",
        data: wpData.geoJson_artwork
      });
    }

    if (wpData?.geoJson_signage?.features.length) {
      commit("SET_geoJson_signage", {
        type: "geojson",
        data: wpData.geoJson_signage
      });
    } else {
      //do nothing;
    }
    if (wpData?.geoJson_local_as_you_like_it?.features.length) {
      commit("SET_geoJson_local_as_you_like_it", {
        type: "geojson",
        data: wpData.geoJson_local_as_you_like_it
      });
    } else {
      //do nothing;
    }

    commit("SET_markerImages", wpData.markerImages);
    commit("SET_sections", wpData.sections);
    commit("SET_categoryLookup", wpData.categoryLookup);
    commit("SET_stackedMarkers", wpData.stackedMarkers);

    commit("SET_allTaxonomiesLookup", wpData.taxonomiesByPostType);
  }
};

const state = {
  allTaxonomiesLookup: {},
  imageLog,
  markerList: {
    type: "geojson",
    data: { type: "FeatureCollection", features: [] }
  },
  geoJson_artwork: {
    type: "geojson",
    data: { type: "FeatureCollection", features: [] }
  },
  geoJson_signage: {
    type: "geojson",
    data: { type: "FeatureCollection", features: [] }
  },
  geoJson_local_as_you_like_it: {
    type: "geojson",
    data: { type: "FeatureCollection", features: [] }
  },
  markerImages: {},
  sections: {},
  categoryLookup: {},
  stackedMarkers: {},
  markersScaleOpacityClass: "markerOpaciyClass10",
  distanceCalculations: {}
};

export default {
  state,
  getters,
  actions,
  mutations
};
