let regionGeoJson = require("./../data/regions.json");

regionGeoJson.features.forEach(row => {
  row.properties.label = row?.properties?.regionName;
  row.properties.backing = "";
});

export default new (class {
  async addLayers({ parentThis, map, appSettings }) {
    if (parentThis && map && appSettings) {
      //do nothing;
    }

    const dataSourceId = "regionStatsPoints";

    map.addSource(dataSourceId, {
      type: "geojson",
      data: regionGeoJson
    });
    /*

const layerId = "regionStatsLayer";

    let options = {
      filter: [">", ["get", "locations"], 0],
      minzoom: 2,
      source: dataSourceId
    };

    map.addLayer({
      id: layerId + "_under",
      type: "symbol",
      ...options,

      layout: {
        "text-max-width": 100,
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,

        "text-field": ["get", "backing"],
        "text-size": 28,
        //        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
        "text-anchor": "left",
        "text-justify": "left"
      },
      paint: {
        "text-color": "#e28721",

        "text-halo-color": "#e28721",
        "text-halo-width": 30
      }
    });

    map.addLayer({
      id: layerId,
      type: "symbol",
      ...options,

      layout: {
        "text-max-width": 100,
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,

        "text-field": ["get", "label"],
        "text-size": 20,
        //        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
        "text-anchor": "left",
        "text-justify": "left"
      },
      paint: {
        "text-color": "white"
        //"text-halo-color": "red",
        //"text-halo-width": 30
      }
    });

    */
  }
})();
