import { mapState } from "vuex";

let layerStatMarkers = [];

let regionGeoJson = require("./../data/regions.json");
let extraLocations = require("./../data/extraLocations.json");

const non2030Brands = ["sydney_central", "somerset"];

extraLocations = extraLocations.map(row => {
  let { Status, Marker_bedsRound, Marker_State, Marker_Brand } = row;

  let status = Status == "Existing" ? 0 : Status == "5-Years" ? 2 : 2;

  row.thisRegion = Marker_State.toLowerCase();
  row.beds = Marker_bedsRound;
  row.thisStatus = status;
  row.thisBrand = Marker_Brand.toLowerCase()
    .replace(" premier", "_premier")
    .replace(" central", "_central");

  delete row.Status;
  delete row.Marker_beds;
  delete row.Marker_bedsRound;
  delete row.Marker_Name;
  delete row.Marker_FN_Name;
  delete row.Marker_Brand;

  row.thisContry = "aus";

  if (row.thisRegion === "nz") {
    row.thisContry = "nz";
  }

  return row;
});

export default {
  data: () => ({
    //layerState: false
    regionStats: {},
    brandStats: {}
  }),

  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings,
      regionSelectOptions: state => state.filters.regionSelectOptions,
      range: state => state.filters.range,
      categoryLookupRaw: state => state.markers.categoryLookup,

      regionSelect: state => state.filters.regionSelect,
      countrySelect: state => state.filters.countrySelect,
      layers: state => state.filters.layers
    }),
    extraTitle() {
      if (this.range[0] === 0 && this.range[1] === 0) {
        return "Current";
      } else if (
        this.range[0] === this.range[1] ||
        this.range[0] < this.range[1]
      ) {
        return "2030";
      } else {
        return "";
      }
    },
    extraLocationsFilteredDown() {
      let rows = [];

      extraLocations.forEach(futureLocation => {
        let { thisBrand, thisStatus, thisContry, thisRegion } = futureLocation;

        let layers = JSON.parse(JSON.stringify(this.layers));

        layers.push("ascott");
        layers.push("sydney_central");

        if (layers.includes("oakwood")) {
          layers.push("oakwood_premier");
        }

        if (
          layers.includes(thisBrand)
          //thisBrand === "oakwood_premier"
          //thisBrand === "sydney_central"
        ) {
          if (this.regionSelect === "all" || this.regionSelect === thisRegion) {
            if (
              this.countrySelect === "all" ||
              this.countrySelect === thisContry
            ) {
              if (this.range[0] <= thisStatus && this.range[1] >= thisStatus) {
                if (
                  (this.extraTitle === "2030" &&
                    !non2030Brands.includes(thisBrand)) ||
                  this.extraTitle !== "2030"
                ) {
                  rows.push(futureLocation);
                }
              }
            }
          }
        }
      });

      return rows;
    }
  },

  methods: {
    updateStatMarkers() {
      // Remove existing markers
      layerStatMarkers.forEach(marker => marker.remove());
      layerStatMarkers = [];

      // Add new markers
      regionGeoJson.features.forEach(feature => {
        // Create a custom div element

        try {
          let { properties } = feature;
          let { keys, locations, regionKey, extraCalss } = properties;

          if (!extraCalss) {
            extraCalss = "";
          }

          if (locations) {
            let mainDivWrapper = document.createElement("div");
            let mainDiv = document.createElement("div");

            mainDivWrapper.className = "mvStatsWrapper";
            mainDiv.className = "mvStats " + extraCalss;

            // Create the label div
            let labelDiv = document.createElement("div");
            labelDiv.className = "mainStatLabel";
            labelDiv.textContent = regionKey.toUpperCase();

            // Create the locations div
            let locationsDiv = document.createElement("div");
            locationsDiv.innerHTML = `
        <div class="statLocations"><nobr><font class="statHeading">Locations: </font>${locations.toLocaleString()}</nobr></div>
      `;

            // Create the keys div
            let keysDiv = document.createElement("div");
            keysDiv.innerHTML = `
        <div class="statKeys"><nobr><font class="statHeading">Keys: </font>${keys.toLocaleString()} </nobr></div>
      `;

            // Append the child divs to the main div
            mainDiv.appendChild(labelDiv);
            mainDiv.appendChild(locationsDiv);
            mainDiv.appendChild(keysDiv);

            mainDivWrapper.appendChild(mainDiv);

            // Create a Marker using the custom div
            let marker = new window.mapboxgl.Marker(mainDivWrapper)
              .setLngLat(feature.geometry.coordinates)
              .addTo(this.map);

            layerStatMarkers.push(marker);
          }
        } catch (error) {
          //do nthign;
        }
      });
    },

    recalcBrandStats() {
      let brandStats = {};

      this.regionSelectOptions; //{ key, name };

      let options = [
        {
          title: "Quest AU",
          brand: "quest",
          contry: ["aus"]
        },

        { title: "Quest NZ", brand: "quest", contry: ["nz", "fiji"] },
        { title: "citadines", brand: "citadines", contry: ["aus"] },
        { title: "Ascott", brand: "ascott", contry: ["aus"] },

        { title: "Oakwood Premier", brand: "oakwood_premier", contry: ["aus"] },
        { title: "Oakwood", brand: "oakwood", contry: ["aus"] },

        { title: "Somerset", brand: "somerset", contry: ["aus"] },
        { title: "Sydney Central", brand: "sydney_central", contry: ["aus"] },
        { title: "lyf", brand: "lyf", contry: ["aus"] },

        {
          title: "Total",
          brand: "all",
          contry: false
        }
      ];

      let thisSourceDataNew = this.map.getSource("places")._data;
      thisSourceDataNew = JSON.parse(JSON.stringify(thisSourceDataNew));

      let features = thisSourceDataNew.features;
      features = JSON.parse(JSON.stringify(features));

      let items = features.map(row => row.properties);

      items = items?.filter(
        properties =>
          properties?.section_slug !== "conference-locations" &&
          properties?.section_slug !== "construction" &&
          !properties?.hide
      );

      options.forEach(region => {
        let { title, brand, contry } = region;

        let keys = 0;
        let locations = 0;

        items.forEach(properties => {
          let thisBrand = properties?.postTaxonomy?.taxSlugs?.markersection[0];
          let thisContry = properties?.postTaxonomy?.taxSlugs?.country[0];

          if (properties.name != "Oakwood - Oakwood Premier Melbourne") {
            if (
              contry === false ||
              (brand === thisBrand && contry.includes(thisContry))
            ) {
              if (
                (this.extraTitle === "2030" &&
                  !non2030Brands.includes(thisBrand)) ||
                this.extraTitle !== "2030"
              ) {
                let beds = properties?.structuredData?.value?.M_NU_BEDS?.value;

                if (!beds) {
                  beds = 100;
                }

                keys += beds;
                locations++;
              }
            }
          }
        });

        //add stat locations;
        this.extraLocationsFilteredDown.forEach(futureLocation => {
          let { thisBrand, thisContry, beds } = futureLocation;

          if (
            contry === false ||
            (brand === thisBrand && contry.includes(thisContry))
          ) {
            keys += beds;
            locations++;
          }
        });

        if (locations) {
          brandStats[title] = { title, brand, contry, keys, locations };
        }
      });
      this.$store.dispatch("statsUpdateBrandStats", brandStats);
      this.brandStats = brandStats;
    },
    recalcRegionStats() {
      let regionStats = {};

      this.regionSelectOptions; //{ key, name };

      let thisSourceDataNew = this.map.getSource("places")._data;
      thisSourceDataNew = JSON.parse(JSON.stringify(thisSourceDataNew));

      let features = thisSourceDataNew.features;
      features = JSON.parse(JSON.stringify(features));

      let items = features.map(row => row.properties);

      items = items?.filter(
        properties =>
          properties?.section_slug !== "conference-locations" &&
          properties?.section_slug !== "construction" &&
          !properties?.hide
      );

      this.regionSelectOptions.forEach(region => {
        let { key: regionKey, name: regionName } = region;

        let keys = 0;
        let locations = 0;
        regionStats[regionKey] = { regionKey, regionName, keys, locations };

        items.forEach(properties => {

          let thisBrand = properties?.postTaxonomy?.taxSlugs?.markersection[0];


          if (properties.name != "Oakwood - Oakwood Premier Melbourne") {
            if (
              properties?.postTaxonomy?.taxSlugs?.state?.length &&
              properties?.postTaxonomy?.taxSlugs?.state.includes(regionKey)
            ) {
              if (
                (this.extraTitle === "2030" &&
                  !non2030Brands.includes(thisBrand)) ||
                this.extraTitle !== "2030"
              ) {
                let beds = properties?.structuredData?.value?.M_NU_BEDS?.value;

                if (!beds) {
                  beds = 100;
                }

                keys += beds;
                locations++;
              }
            }
          }
        });

        //add stat locations;
        this.extraLocationsFilteredDown.forEach(futureLocation => {
          let { thisRegion, beds } = futureLocation;

          if (regionKey === thisRegion) {
            keys += beds;
            locations++;
          }
        });

        regionStats[regionKey] = { regionKey, regionName, keys, locations };
      });

      let ausKeys = 0,
        ausLocations = 0;

      Object.keys(regionStats).forEach(key => {
        let item = regionStats[key];

        let { regionKey, keys, locations } = item;

        if (!["nz", "fiji"].includes(regionKey)) {
          ausKeys += keys;
          ausLocations += locations;
        }
      });

      regionStats["aus"] = {
        regionKey: "aus",
        regionName: "Australia",
        keys: ausKeys,
        locations: ausLocations
      };

      let regionStatsPointsData = this.map.getSource("regionStatsPoints")._data;

      regionStatsPointsData.features.forEach(row => {
        try {
          let regionKey = row?.properties?.regionKey;

          let { regionName, keys, locations } = regionStats[regionKey];
          row.properties.locations = this.showStatusLabels ? locations : 0;
          row.properties.keys = this.showStatusLabels ? keys : 0;
          row.properties.label = `${regionKey.toUpperCase()} | Locations: ${locations} Keys: ${keys}`;

          row.properties.keysAct = keys ? keys : 0;
          row.properties.locationsAct = locations ? locations : 0;

          if (regionName) {
            //do nothing;
          }
        } catch (error) {
          //do nothing;
        }
      });

      this.regionStats = regionStats;
      this.$store.dispatch("statsUpdateRegionStats", regionStats);

      this.map.getSource("regionStatsPoints").setData(regionStatsPointsData);

      this.updateStatMarkers();
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
