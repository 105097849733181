const targets = [
  {
    postId: "signage",
    taxonomies: ["signage_section"],
    sectionTaxonomies: "signage_section",
    listTaxonomies: "signage_section",
    geoData: true,
    forceMarkType: "signage"
  },

  {
    postId: "artwork",
    taxonomies: ["artwork_section"],
    sectionTaxonomies: "artwork_section",
    listTaxonomies: "artwork_section",

    geoData: true,
    forceMarkType: "artwork"
  },
  {
    postId: "markers",
    taxonomies: [
      "markersection",
      "hotel_programs",
      "sectors",
      "state",
      "country",
      "award"
    ],
    sectionTaxonomies: "markersection",
    listTaxonomies: "markersection",
    geoData: true
  },
  {
    postId: "local_as_you_like_it",
    taxonomies: ["alayli_type"],
    sectionTaxonomies: "alayli_type",
    listTaxonomies: "alayli_type",
    forceMarkType: "local_as_you_like_it",
    geoData: true
  }
];

module.exports = {
  targets
};
