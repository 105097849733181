<template>
  <v-card elevation="6" x_shaped v-if="ready" class="pb-1">
    <v-carousel
      eager
      v-if="properties.media && properties.media.length"
      hide-delimiters
      :show-arrows="properties.media.length > 1"
      :height="'150px'"
    >
      <v-carousel-item
        v-for="(item, i) in properties.media"
        :key="i"
        :src="item.src_thumb"
      ></v-carousel-item>
    </v-carousel>

    <v-card-text class="px-2 pb-0 mb-1">
      <div class="d-flex flex-row">
        <img
          height="40px"
          width="40px"
          style="margin:2px"
          v-show="properties.list_image_obj && properties.list_image_obj.thumb"
          :src="`${properties.list_image_obj.thumb}`"
        />

        <center class="headingBlock mt-0 pb-1 flex-grow-1">
          <div class="secondary--text text--darken-1">
            <div v-if="properties.Point_Name" class="mainHeading  ">
              <engAndTrans :value="properties.Point_Name" />
            </div>
          </div>
        </center>

        <img
          v-if="false"
          height="40px"
          width="40px"
          style="margin:2px"
          v-show="properties.list_image_obj && properties.list_image_obj.thumb"
          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        />
      </div>
    </v-card-text>

    <center>
      <div
        translate="no"
        class="notranslate subHeading"
        v-if="
          properties.structuredData &&
            properties.structuredData.subtitle &&
            properties.structuredData.subtitle.M_ST_Address_Title &&
            properties.structuredData.subtitle.M_ST_Address_Title.value
        "
      >
        {{ properties.structuredData.subtitle.M_ST_Address_Title.value }}
      </div>
    </center>

    <PointDescriptionBody
      :functions="{ updateShowMore, openSidebarRemote }"
      type="asLocalPopup"
      :Point_Description="Point_Description"
      :showMore="showMore"
    />

    <extraInfoBlocks
      :appSettings="appSettings"
      :properties="properties"
      :location="properties"
      :media="[]"
      :Point_Name="properties.Point_Name"
      :Site_Marker_Number="properties.Site_Marker_Number"
      :structuredData="properties.structuredData"
      :functions="{ openYoutube, setSound, getAudioDetails, pointSet }"
      :isPopup="true"
    />

    <extraLinkBlocks
      :appSettings="appSettings"
      :properties="properties"
      :location="properties"
      :media="[]"
      :Point_Name="properties.Point_Name"
      :Site_Marker_Number="properties.Site_Marker_Number"
      :structuredData="properties.structuredData"
      :functions="{ openYoutube, setSound, getAudioDetails, pointSet }"
      :isPopup="true"
      :isJustLinks="true"
    />

    <v-card-actions
      class="pb-1 mb-0 pt-0 mt-1"
      v-if="$vuetify.breakpoint.mdAndDown || !appSettings.autoOpenMoreInfo"
    >
      <v-btn
        class="pb-0 mb-0 pt-0 mt-0"
        :color="'#a2c2cd'"
        :xcolor="appSettings.dialogActionButtonColor"
        @click="openSidebar(properties.index)"
        :width="appSettings.popupButtonWidth"
      >
        More Info
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        v-if="false"
        right
        :color="appSettings.dialogActionButtonColor"
        text
        @click="clearAllPopups"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { eventBus } from "../../main";

import Vue from "vue";

import extraInfoBlocks from "./../info/extraInfoBlocks.vue";
import extraLinkBlocks from "./../info/extraLinkBlocks.vue";
import engAndTrans from "./../ui/engAndTrans.vue";
import PointDescriptionBody from "./../ui/PointDescriptionBody.vue";

export default Vue.extend({
  components: {
    extraInfoBlocks,
    extraLinkBlocks,
    engAndTrans,
    PointDescriptionBody
  },
  data: () => ({
    showMore: false,
    ready: false,
    showCarousel: false,
    media: []
  }),

  props: {
    //goToPointByPointName: Function,
    getAudioDetails: Function,
    openYoutube: Function,
    setSound: Function,
    openSidebar: Function,
    clearAllPopups: Function,
    appSettings: Object,
    pointSet: Object,
    properties: {
      required: true,
      type: Object
    },
    vuetifyCopy: {
      required: true,
      type: Object
    },
    browserDetectCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    Point_Description() {
      return this.properties?.content?.rendered;
    }
  },
  mounted() {
    try {
      let parentPostId = this?.properties?.M_LINKED_POST_linkedMarker;

      this.properties.structuredData["flytopoint"] = {
        M_FLYOBJ_POINT: {
          label: "fly to",
          sectionKey: "flytopoint",
          type: "flyto",
          order: 5,
          key: "M_FLYOBJ_POINT",
          sectionOrder: 1,
          showInPopUp: true,
          value: parentPostId,
          sectionCount: 1
        }
      };
    } catch (error) {
      //do nothing;
    }

    this.$vuetify = this.vuetifyCopy;
    if (this.browserDetectCopy) {
      this.$browserDetect = this.browserDetectCopy;
    }
    this.ready = true;
    this.showCarousel = true;
  },

  methods: {
    openSidebarRemote(alsoShowMore) {
      this.openSidebar(this.properties.index);

      if (alsoShowMore) {
        eventBus.$emit("pointDiscription_expand");
      }
    },

    updateShowMore(value) {
      this.showMore = value ? value : false;
    }
  }
});
</script>

<style></style>
