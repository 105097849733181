import _ from "lodash";

var delayAtNormal = 100;
const delayAtDelay = 4000;

const delayOnNthItem = 40;

var doneNormal = false,
  doneDelay = false;

const sizes = {
  marker: 200,
  marker_lg: 640,
  marker_md: 320,
  marker_vs: 100
};

const getResizeRatio = (currentRatio, oldSize, newSize) => {
  try {
    if (
      currentRatio &&
      oldSize &&
      newSize &&
      sizes[oldSize] &&
      sizes[newSize]
    ) {
      let scale = sizes[oldSize] / sizes[newSize];
      if (scale) {
        currentRatio = currentRatio / scale;
      }
    }
  } catch (error) {
    //do nothing;
  }

  return currentRatio;
};

const loadImage = async function(map, imageHref, imageId, pixelRatio) {
  const myPromise = new Promise(resolve => {
    try {
      map.loadImage(
        `${imageHref}?cacheblock=true`,
        //imageHref,
        async (error, image) => {
          if (error) {
            resolve({ done: false, error: true });
            //console.error("error loading mapbox asset");
          } else {
            map.removeImage(imageId); //remove dummy image
            //add loaded image
            map.addImage(imageId, image, { pixelRatio: pixelRatio });

            resolve({ done: true });
          }
        }
      );
    } catch (error) {
      resolve({ done: false, error: true });
    }
  });

  return myPromise;
};

function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const genNullIcon = async function(map, blankImage, width, imageObj) {
  let imageId = String(imageObj.id);
  let imageHref = (imageHref = imageObj.marker
    ? imageObj.marker
    : imageObj.href);

  map.addImage(imageId, {
    width: width,
    height: width,
    data: blankImage
  });
  return imageHref;
};

const genActIcon = async function(
  map,
  blankImage,
  width,
  imageObj,
  categoryLookup,
  index
) {
  var pixelRatio = 2;
  let imageId = String(imageObj.id);
  let imageHref = (imageHref = imageObj.marker
    ? imageObj.marker
    : imageObj.href);

  if (imageObj.normal && !doneNormal) {
    doneNormal = true;
    await wait(delayAtNormal);
  }

  if (imageObj.delay && !doneDelay) {
    doneDelay = true;
    await wait(delayAtDelay);
  }

  if (imageObj.isArtwork === true) {
    try {
      if (
        imageObj.mo_scale_factor_media &&
        !isNaN(imageObj.mo_scale_factor_media) &&
        parseFloat(imageObj.mo_scale_factor_media)
      ) {
        let extraFactor = parseFloat(imageObj.mo_scale_factor_media);
        if (extraFactor > 0 && extraFactor <= 4 && extraFactor !== 1) {
          pixelRatio = pixelRatio / extraFactor;
        }
      } else {
        //do nothing;
      }
      let size = "marker_lg";
      size = "marker_md";

      imageHref = imageObj[size];
      pixelRatio = getResizeRatio(pixelRatio, "marker", size);
    } catch (error) {
      //do nothing;
    }
  } else if (imageObj.marker_vs) {
    try {
      if (
        imageObj.mo_scale_factor_media &&
        !isNaN(imageObj.mo_scale_factor_media) &&
        parseFloat(imageObj.mo_scale_factor_media)
      ) {
        let extraFactor = parseFloat(imageObj.mo_scale_factor_media);
        if (extraFactor > 0 && extraFactor <= 4 && extraFactor !== 1) {
          pixelRatio = pixelRatio / extraFactor;
        }
      } else {
        //do nothing;
      }
    } catch (error) {
      //do nothing
    }

    //imageHref = imageObj.marker_vs;
    //pixelRatio = getResizeRatio(pixelRatio, "marker", "marker_vs");

    //imageHref = imageObj.marker;
    //pixelRatio = getResizeRatio(pixelRatio, "marker", "marker");

    imageHref = imageObj.marker_md;
    pixelRatio = getResizeRatio(pixelRatio, "marker", "marker_md");
  }

  if (index % delayOnNthItem === 0) {
    await loadImage(map, imageHref, imageId, pixelRatio);
  } else {
    loadImage(map, imageHref, imageId, pixelRatio);
  }

  return imageHref;
};

const updateImagePriority = (
  markerImagesn,
  categoryLookup,
  priorityOptions
) => {
  try {
    let imageOrder = [],
      skip = [],
      normal = [],
      delay = [],
      priority = [],
      tasking = {
        skip: [],
        normal: [],
        delay: [],
        priority: []
      };

    _.forEach(categoryLookup, (cat, catKey) => {
      try {
        let { taxonomy: typeSlug } = cat;

        let thisPriority = priorityOptions[typeSlug];

        if (thisPriority) {
          //do jnothing;
          if (thisPriority?.behaviour === "cluster") {
            if (thisPriority?.load.includes(catKey)) {
              tasking.normal.push(catKey);
            } else {
              tasking.skip.push(catKey);
            }
          } else {
            //normal;
            if (thisPriority?.skip.includes(catKey)) {
              tasking.skip.push(catKey);
            } else if (thisPriority?.priority.includes(catKey)) {
              tasking.priority.push(catKey);
            } else if (thisPriority?.delay.includes(catKey)) {
              tasking.delay.push(catKey);
            } else {
              tasking.normal.push(catKey);
            }
          }
        } else {
          throw { error: true, justPush: true };
        }
      } catch (error) {
        //normal.push(key);

        tasking.normal.push(catKey);
      }
    });

    _.forEach(markerImagesn, image => {
      let { section_slug } = image;

      if (tasking?.priority.includes(section_slug)) {
        image.priority = true;
        priority.push(image);
      } else if (tasking?.skip.includes(section_slug)) {
        skip.push(image);
      } else if (tasking?.delay.includes(section_slug)) {
        image.delay = true;
        delay.push(image);
      } else {
        image.normal = true;
        normal.push(image);
      }
    });

    //let tempImageOrder = [...priority, ...normal, ...delay];

    let tempImageOrder = [];
    priority.forEach(row => {
      tempImageOrder.push(row);
    });
    normal.forEach(row => {
      tempImageOrder.push(row);
    });
    delay.forEach(row => {
      tempImageOrder.push(row);
    });

    if (!priority.length) {
      delayAtNormal = 0;
    }

    imageOrder = [];
    tempImageOrder.forEach(row => {
      let found = imageOrder.filter(image => image.id === row.id);

      if (found.length < 1) {
        imageOrder.push(row);
      }
    });

    return imageOrder;
  } catch (error) {
    //do nothing
    return markerImagesn;
  }
};

export default new (class {
  async createWpmarkerImages(
    map,
    markerImagesn,
    categoryLookup,
    markerImageAssetOrderPriority
  ) {
    //this dynamicly loads 60 images for each of the project marker

    markerImagesn = updateImagePriority(
      markerImagesn,
      categoryLookup,
      markerImageAssetOrderPriority
    );

    let imageList = _.values(markerImagesn);

    var N = 60;
    try {
      N = imageList.length;
      //N = markerList.data.features.length + 1;
    } catch (error) {
      N = 60;
    }

    const arr = [...Array(N + 1).keys()].slice(1);

    // this creates a blank image while the icons load.
    var width = 1;
    var bytesPerPixel = 4;
    var blankImage = new Uint8Array(width * width * bytesPerPixel);
    for (var x = 0; x < width; x++) {
      for (var y = 0; y < width; y++) {
        var offset = (y * width + x) * bytesPerPixel;
        blankImage[offset + 0] = 1; // red
        blankImage[offset + 1] = 1; // green
        blankImage[offset + 2] = 1; // blue
        blankImage[offset + 3] = 0; // alpha
      }
    }

    //genIcon(map, blankImage, width, imageId);

    const loadNumberIconsLoop = async act => {
      let imageLog = [];
      for (let index = 0; index < arr.length; index++) {
        // Get num of each fruit
        //let imageId = "icon" + arr[index];

        let imageObj = imageList[index];
        let tempUrl = act
          ? await genActIcon(
              map,
              blankImage,
              width,
              imageObj,
              categoryLookup,
              index
            )
          : await genNullIcon(
              map,
              blankImage,
              width,
              imageObj,
              categoryLookup,
              index
            );

        if (tempUrl) {
          imageLog.push(tempUrl);
        }
      }
      return imageLog;
    };

    var startDate = new Date();

    await loadNumberIconsLoop(false);
    let imageLog = await loadNumberIconsLoop(true);

    var endDate = new Date();
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;

    if ((seconds, imageLog)) {
      //do nothing;
    }
  }
})();
