// import Vue from 'vue'
const state = {
  brandStats: {},
  regionStats: {}
};
const mutations = {
  UPDATE_BrandStats(state, newValue) {
    state.brandStats = newValue;
  },
  UPDATE_RegionStats(state, newValue) {
    state.RegionStats = newValue;
  }
};
const actions = {
  statsUpdateRegionStats({ commit }, command) {
    commit("UPDATE_RegionStats", command);
  },
  statsUpdateBrandStats({ commit }, command) {
    commit("UPDATE_BrandStats", command);
  }
};
const getters = {};

export default {
  state,
  getters,
  actions,
  mutations
};
