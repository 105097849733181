var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentValue),expression:"currentValue"}]},[_c('v-card-text',[_c('b',[_vm._v("As Local As You Like It")]),_c('v-list',{staticClass:"px-0 mx-0",attrs:{"dense":""}},_vm._l((_vm.localAs_pros),function(item,i){return _c('v-list-item',{key:i,attrs:{"dense":""},on:{"click":function($event){return _vm.localAsClick(item)}}},[_c('v-list-item-icon',{staticClass:"mr-3 my-0",staticStyle:{"align-self":"center"},attrs:{"dense":""}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.list_image_obj && item.list_image_obj.thumb),expression:"item.list_image_obj && item.list_image_obj.thumb"}],attrs:{"src":("" + (item.list_image_obj.thumb))}})]),_c('v-list-item-content',{staticClass:"my-0 py-0",attrs:{"dense":""}},[_c('v-list-item-title',{staticClass:"ma-0 pa-0 ",domProps:{"textContent":_vm._s(item.Point_Name)}})],1)],1)}),1),(
        _vm.properties.structuredData &&
          _vm.properties.structuredData.note &&
          _vm.properties.structuredData.note
            .M_TA_as_local_as_you_like_it_details &&
          _vm.properties.structuredData.note.M_TA_as_local_as_you_like_it_details
            .value
      )?_c('div',{staticClass:"pb-2",domProps:{"innerHTML":_vm._s(
        _vm.properties.structuredData.note.M_TA_as_local_as_you_like_it_details
          .value
      )}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }