<template>
  <div class="siteKeyAct_wrapper  pb-10 pb-12 mb-1">
    <div class="filterWrapper" v-if="!currentCountryNotEnglish">
      <v-text-field
        :background-color="appSettings.filterButtonColor"
        solo
        dark
        v-model="wordFilter"
        label="List Filter"
        prepend-inner-icon="mdi-text-search-variant"
        clearable
      ></v-text-field>
    </div>

    <v-list :class="[wordFilterClean ? 'filterRunning' : '']">
      <v-list-item-group v-model="sidebarSiteActPlus" color="info">
        <v-list-group
          eager
          v-for="section in menuListItems"
          :key="section.key"
          xv-model="section.active"
          no-action
          v-show="
            !section.hideSection &&
              (!wordFilterClean || wordFilterCatList[section.key])
          "
          v-bind:class="['catKeyAct_' + section.key]"
          :aria-label="`${section.title}`"
        >
          <template v-slot:activator>
            <v-list-item-avatar
              size="30"
              v-if="siteListSettings.sectionUseCircle"
              :color="section.color"
              class="sectionAvatar"
            >
            </v-list-item-avatar>

            <v-list-item-avatar
              size="40"
              tile
              v-if="!siteListSettings.sectionUseCircle"
            >
              <div style="height: 49px; width: 50px; overflow:hidden;">
                <img
                  style="height: 75px; width: 50px; object-fit: contain;"
                  :src="section.setup.list_image_obj.marker"
                  :aria-label="`${section.title}`"
                />
              </div>
            </v-list-item-avatar>

            <v-list-item-content eager>
              <v-list-item-title
                :aria-label="`${section.title}`"
                class="text-header text-wrap font-weight-medium font-weight-normal secondary--text"
              >
                <engAndTrans :value="section.title" />
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="site in section.items">
            <v-list-item
              eager
              v-show="
                !wordFilterClean ||
                  (wordFilterClean &&
                    site.title &&
                    site.title.toLowerCase() &&
                    site.title.toLowerCase().includes(wordFilterClean))
              "
              color="info"
              :value="1000000 + site.properties.index"
              v-bind:class="[
                'listItemAct',
                'ml-0',
                'siteKeyAct_' + site.properties.index,
                sidebarSite === site.properties.index ? 'currentlyOpen' : ''
              ]"
              :key="site.key"
              @click.prevent="focusOnSite(site.properties.index)"
              @mouseenter="hoverOnListItem(site.properties.index)"
              v-on:dblclick="loadMoreInfo(site.properties.index)"
              :aria-label="`${site.title}`"
            >
              <v-list-item-avatar
                size="20"
                v-if="siteListSettings.itemUseCircle"
                :color="section.color"
                class="itemAvatar"
              >
              </v-list-item-avatar>

              <v-list-item-avatar
                tile
                class="itemAvatar"
                v-if="!siteListSettings.itemUseCircle"
              >
                <div style="height: 39px; width: 40px; overflow:hidden;">
                  <img
                    v-if="false"
                    style="height: 60px; width: 40px; object-fit: contain;"
                    :src="section.setup.list_image_obj.marker"
                    :aria-label="`${section.title}`"
                  />
                </div>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="text-header text-wrap  secondary--text"
                >
                  <engAndTrans :value="site.listTitle" />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list-item-group>
    </v-list>

    <center v-if="!sites.length">
      <br />
      <div class="ml-4 heading right">... no items</div>
    </center>

    <v-btn
      v-if="!sites.length"
      depressed
      :color="appSettings.mediaButtonColor"
      :width="appSettings.drawButtonWidth"
      class="ma-2 white--text"
      @click="resetFilters()"
    >
      <v-icon left>mdi-cached</v-icon>

      Reset filters
    </v-btn>

    <div style="display:none;;">
      sidebarSite:{{ sidebarSite }};; sidebarSiteAct:{{ sidebarSiteAct }}
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
const simulateClick = function(elem) {
  // Create our event (with options)
  try {
    var evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);

    if (evt && canceled) {
      //do nothingk;
    }
  } catch (error) {
    //do nothing
  }
};

const openTab = (section_slug, secondGo) => {
  var target = document.querySelector(
    `.catKeyAct_${section_slug} > div.v-list-group__header `
  );

  let value = target?.getAttribute("aria-expanded");
  if (value === "true" || value === true) {
    value = true;
  } else {
    value = false;
    simulateClick(target);
  }
  if (!secondGo) {
    setTimeout(() => {
      openTab(section_slug, true);
    }, 200);
  }
};

import { mapState } from "vuex";
import { eventBus } from "../main";

import engAndTrans from "./ui/engAndTrans.vue";

export default {
  name: "SiteList",
  props: {
    isOpen: Boolean,
    parentDrawer: Boolean
  },
  components: {
    engAndTrans
  },
  computed: {
    ...mapState({
      sidebarSite: state => state.navigation.sidebarSite,
      markerList: state => state.markers.markerList,
      categoryLookup: state => state.markers.categoryLookup,
      listCategoryLookup: state => state.markers.categoryLookup, //listCategoryLookup
      markerImages: state => state.markers.markerImages,
      baseurllocation: state => state.navigation.baseurllocation,
      appSettings: state => state.global.appSettings,
      layers: state => state.filters.layers,
      visibleMarkerPostIds: state => state.filters.visibleMarkerPostIds,

      applicationTypeFilters: state => state.filters.applicationTypeFilters,
      //defaultLayers: state => state.filters.defaultLayers

      currentCountryNotEnglish: state =>
        state.translator.currentCountryNotEnglish
    }),

    siteListSettings() {
      return this?.appSettings?.siteListSettings
        ? this?.appSettings?.siteListSettings
        : {};
    },
    wordFilterClean() {
      let wordFilter = "";

      if (this.currentCountryNotEnglish !== true) {
        try {
          if (
            this.wordFilter &&
            this.wordFilter?.length > 2 &&
            this.wordFilter.toLowerCase()
          ) {
            wordFilter = this.wordFilter.toLowerCase();
          }
        } catch (e) {
          //do nothingk
        }
      }
      return wordFilter;
    },

    listCategoriesLayers() {
      return Object.keys(this.listCategoryLookup);
    },

    sites() {
      let filters = this.layers;
      let options = this.applicationTypeFilters;

      let sites = this.markerList.data.features;

      //filter sites;
      sites
        //copyFilterCodeFromHere
        .forEach(feature => {
          let filterMatch = true;
          let layerMatch = true;

          try {
            let applyDirect =
              feature?.properties?.structuredData?.value?.M_C_YN_applyDirect
                ?.value == "Yes"
                ? true
                : false;
            filterMatch =
              options.includes("All") ||
              (options.includes("Participating") && applyDirect === false) ||
              (options.includes("Apply Direct") && applyDirect === true)
                ? true
                : false;
          } catch (error) {
            //do nothing!!
          }

          try {
            layerMatch = filters.includes(feature.properties.section_slug)
              ? true
              : false;
          } catch (error) {
            //do nothing!!
          }

          if (layerMatch && filterMatch) {
            feature.properties.hide = false;
            feature.geometry.coordinates = [
              feature.properties.Latitude,
              feature.properties.Longitude
            ];
          } else {
            feature.properties.hide = true;
            feature.geometry.coordinates = [0, 0];
          }
        });
      //copyFilterCodeToHere

      return sites.filter(feature => !feature?.properties?.hide);
    },
    origin() {
      return window.location.origin;
    },

    showMarkerLayer() {
      let layers = [];
      for (const key in this.categoryLookup) {
        const row = this.categoryLookup[key];
        //is main marker and is visiable
        if (row.section_type === "main_marker" && this.layers.includes(key)) {
          layers.push(key);
        }
      }
      return layers;
    },

    menuListItems() {
      let section_slugs = {};

      let itemsObj = {};
      this.sites.forEach(marker => {
        let { properties } = marker;

        let { section_slug, list_section_title, post_id } = properties;

        let hideSection = false;

        if (this?.hideSectionSlugs?.includes(section_slug)) {
          hideSection = true;
        }

        let hideMarker = true;

        if (this?.visibleMarkerPostIds?.includes(post_id)) {
          hideMarker = false;
        }

        if (hideMarker != true) {
          if (!itemsObj[section_slug]) {
            itemsObj[section_slug] = {
              title: list_section_title,
              key: section_slug,
              avatar: this.listCategoryLookup[section_slug]?.logo_image_obj
                ?.href,
              setup: this.listCategoryLookup[section_slug],
              items: [],
              hideSection: hideSection
            };
          }

          let statusText =
            properties?.structuredData?.value?.M_RN_status?.value;
          let status = properties?.structuredData?.value?.M_RN_status?.value;
          if (statusText === 0) {
            //status = " - Current";
            statusText = " | Existing";
          } else if (statusText === 1) {
            //statusText = " | 1 Year";
            statusText = " | Future";
          } else if (!isNaN(statusText)) {
            status = 5;
            statusText = ` | Under Construction`;
          } else {
            statusText = " | Existing";
          }
          if (statusText === " | Existing") {
            status = 0;
          }

          itemsObj[section_slug].items.push({
            title: properties.Point_Name,
            listTitle: `${properties.Point_Name}`,
            status,
            statusText,
            key: properties.slug,
            properties: properties,
            index: properties.index
          });

          section_slugs[section_slug] = section_slug;
        }
      });

      Object.keys(section_slugs).forEach(section_slug => {
        try {
          itemsObj[section_slug].items = itemsObj[
            section_slug
          ].items.sort((a, b) => a?.title.localeCompare(b?.title));
        } catch (error) {
          //do nothing;
          //console.error("error sorting");
        }
      });

      delete itemsObj["conference-locations"];

      let itemsObjNew = {};

      let statusTypes = [
        {
          status: 0,
          statusText: "Existing"
        },
        {
          status: 1,
          statusTextOld: "1 Year",
          statusText: "Future"
        },
        {
          status: 5,
          statusTextOld: "5 Years",
          statusText: "Under Construction"
        }
      ];

      Object.keys(itemsObj).forEach(section_slug => {
        let oldSection = itemsObj[section_slug];

        statusTypes.forEach(thisStatus => {
          let { status, statusText } = thisStatus;

          let newSection = JSON.parse(JSON.stringify(oldSection));
          let { key, title, items, hideSection, setup, avatar } = newSection;

          let newSlug = key + status;
          let newTitle = title + " | " + statusText;

          let newItems = JSON.parse(JSON.stringify(items));
          try {
            newItems = newItems.filter(row => {
              return row.status == status;
            });

            newItems.forEach((row, index) => {
              row.order = index + 1;
            });
          } catch (error) {
            //do nothing;
            //console.error("error filtering");
          }

          let color = this.colorLookups[key][status];

          if (newItems.length) {
            itemsObjNew[newSlug] = {
              color,
              title: newTitle,
              key: newSlug,
              items: newItems,
              hideSection,
              setup,
              avatar
              //oldKey: key,
              //oldTitle: title,
              //oldItems: items
            };
          }
        });
      });

      return itemsObjNew;
    },

    wordFilterCatList() {
      let lookupCount = {};

      for (const [section_slug, itemsObj] of Object.entries(
        this.menuListItems
      )) {
        lookupCount[section_slug] = false;

        if (this?.wordFilterClean?.length > 2) {
          lookupCount[section_slug] = false;

          itemsObj.items.forEach(site => {
            if (site.title.toLowerCase().includes(this.wordFilterClean)) {
              lookupCount[section_slug] = true;
            }
          });
        }
      }

      return lookupCount;
    }
  },
  data: () => ({
    colorLookups: {
      quest: {
        "0": "#b64d1d",
        "1": "#d57a56",
        "5": "#dc9477"
      },
      oakwood: {
        "0": "#34367f",
        "1": "#4c4d81",
        "5": "#6ca9f3"
      },
      lyf: {
        "0": "#3e7b44",
        "1": "#58a63b",
        "5": "#acf791"
      },
      citadines: {
        "0": "#375c76",
        "1": "#6b95af",
        "5": "#62e2fe"
      },

      somerset: {
        "0": "#c6b900",
        "1": "#c6b900",
        "5": "#c6b900"
      }
    },

    wordFilter: "",
    cancelScroll: null,
    sidebarSiteAct: false,
    sidebarSiteActPlus: false,
    holdScroll: false,
    hideSectionSlugs: ["fighting_extinction_species"],
    sectionClasses: {
      growing_wild: "shadow",
      trail_elephants: "shadow",
      eat_play: "shadow"
    }
    //oldSlectedSite: false
  }),
  methods: {
    hoverOnListItem(index) {
      if (index) {
        //do nothingl
      }
      //eventBus.$emit("markerEffects_bouncMarker", index);
    },

    resetFilters() {
      //this.$store.dispatch("filters_controlsUpdateLayers", this.defaultLayers);
      this.$store.dispatch("filters_resetLayers");
    },

    loadIntro() {
      eventBus.$emit("loadIntroPopup");
    },
    loadMoreInfo(index) {
      this.wordFilter = "";
      this.$store.dispatch("navUpdateSidebarSite", index);
    },
    focusOnSite(index) {
      this.$store.dispatch("updateMinDetails", false);

      this.wordFilter = "";
      eventBus.$emit("nav_SetTableMapView", "map");
      eventBus.$emit("nav_map_flyToOpenPopUp", index);
      setTimeout(() => {
        this.holdScroll = false;
      }, 200);
      try {
        if (this.sites[index]) {
          try {
            //window.gtag("event","sbClick",this.sites[index].properties.Site_Marker_Number);
            window.mvAnalyticEvent(
              "ui",
              "sbClick",
              this.sites[index].properties.Site_Marker_Number,
              false
            );
          } catch (error) {
            //do nothing
          }

          let coordinates;
          coordinates = this.sites[index].geometry.coordinates
            ? this.sites[index].geometry.coordinates
            : this.sites[index].properties.Longitude &&
              this.sites[index].properties.Latitude
            ? [
                this.sites[index].properties.Longitude,
                this.sites[index].properties.Latitude
              ]
            : false;

          if (coordinates) {
            this.$store.dispatch("navUpdateFocusSite", index);
          }
        }
      } catch (error) {
        //do nothing
      }
    },
    scrollToSite(targetSiteIndex) {
      this.wordFilter = "";
      if (targetSiteIndex === "wrapper") {
        //        active: false,
        let element = "siteKeyAct_wrapper";
        this.cancelScroll = this.$scrollTo(element);
      } else {
        try {
          let [targetSite] = this.sites.filter(
            site => site?.id === targetSiteIndex
          );

          let section_slug = targetSite.properties.section_slug;

          let status =
            targetSite?.properties?.structuredData?.value?.M_RN_status?.value;

          let section_slug_and_status = section_slug + status;
          //this

          this.menuListItems[section_slug_and_status].active = true;

          openTab(section_slug_and_status);

          let element = ".catKeyAct_" + section_slug_and_status;
          this.sidebarSiteActPlus = 1000000 + targetSiteIndex;

          //let shouldScrollToItem = false;

          try {
            let item = this.menuListItems[section_slug_and_status].items.filter(
              row => row.index === targetSiteIndex
            )[0];

            let { order: itemOrder } = item;

            let sectionOrder =
              Object.keys(this.menuListItems).indexOf(section_slug_and_status) +
              1;

            let itemHeight = 48;
            let uiHight = 150;

            let innerHeight = window.innerHeight;
            let currentItemOrder = itemOrder + sectionOrder;

            let totalItemsOnScreen = Math.round(
              (innerHeight - uiHight) / itemHeight
            );

            if (currentItemOrder > totalItemsOnScreen) {
              //shouldScrollToItem = true;
              element = ".siteKeyAct_" + targetSiteIndex;
            } else {
              element = "siteKeyAct_wrapper";
            }
          } catch (error) {
            //do nothing'
          }

          setTimeout(() => {
            this.cancelScroll = this.$scrollTo(element);

            this.sidebarSiteActPlus = 1000000 + targetSiteIndex;
          }, 280);
        } catch (error) {
          //console.error(error, targetSiteIndex);
        }
      }
      //to item
      /*
      if (!this.holdScroll && newValue != "false" && newValue != false) {
        let element = ".siteKeyAct_" + newValue;
        this.cancelScroll = this.$scrollTo(element);
      }
*/
    },
    scrollToTopIfNull() {
      setTimeout(() => {
        if (this.sidebarSite == false) {
          setTimeout(() => {
            if (this.sidebarSite == false) {
              setTimeout(() => {
                if (this.sidebarSite == false) {
                  this.scrollToSite("wrapper");
                }
              }, 50);
            }
          }, 50);
        }
      }, 50);
    }
  },
  created() {
    eventBus.$on("nav_siteList_scrollToTop", () => {
      this.scrollToSite("wrapper");
    });
    eventBus.$on("nav_filterRun", () => {
      this.wordFilter = "";
    });
  },
  watch: {
    parentDrawer(newValue) {
      if (newValue === true && this.sidebarSite == false) {
        this.scrollToTopIfNull();
      }
    },
    sidebarSiteActPlus(newValue) {
      if (this.currentCountryNotEnglish && !isNaN(newValue)) {
        //eventBus.$emit("mvTrans_updateNow");
      }
    },
    sidebarSiteAct(newValue) {
      this.sidebarSiteActPlus = 1000000 + newValue;
    },
    isOpen(newValue) {
      if (newValue === true) {
        if (this.sidebarSite == false) {
          this.scrollToTopIfNull();
        } else {
          this.scrollToSite(this.sidebarSiteAct);
        }
      }
    },
    sidebarSite(newValue /* oldValue*/) {
      this.sidebarSiteAct = newValue;

      if (newValue) {
        this.scrollToSite(newValue);
      } else {
        this.scrollToTopIfNull();
      }
    }
  },

  async mounted() {}
};
</script>
<style>
.text-header {
  font-size: 1.1em;
}

.currentlyOpen {
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.3;
}

.avatarSiteNumber {
  position: absolute;
  left: 0;
  top: 8px;
  width: 100%;
  //text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.26), 1px -1px 0 rgba(0, 0, 0, 0.26), -1px 1px 0 rgba(0, 0, 0, 0.26), 1px 1px 0 rgba(0, 0, 0, 0.26);
}

.sectionAvatar {
  color: white;
}

.itemAvatar {
  margin: 0;
}

.v-application--is-ltr
  .siteListWrapper
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 26px;
}

.v-application--is-ltr
  .siteListWrapper
  .v-list-item__action:last-of-type:not(:only-child),
.v-application--is-ltr
  .siteListWrapper
  .v-list-item__avatar:last-of-type:not(:only-child),
.v-application--is-ltr
  .siteListWrapper
  .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: -16px;
}

.filterWrapper {
  margin-bottom: -37px;

  margin-left: -2px;
  margin-right: -2px;
}

.listItemAct:last-child {
  margin-bottom: 12px;
}

.filterRunning .v-list-group__items {
  display: block !important;
}
.filterRunning .v-list-item__icon.v-list-group__header__append-icon i {
  display: none !important;
}

.v-avatar.roundedSquare {
  border-radius: 35%;
}

.listItemAct {
  min-height: 42px;
  height: auto;
}

.currentCountryNotEnglish .listItemAct {
  height: auto;
}

.currentCountryNotEnglish .listItemAct v-list-item__content {
  padding: 0;
}
.shadow .sectionAvatar > img {
  -webkit-filter: drop-shadow(1px 1px 0 rgba(255, 255, 255, 0.1))
    drop-shadow(-1px -1px 0 rgba(255, 255, 255, 0.1))
    drop-shadow(1px -1px 0 rgba(255, 255, 255, 0.1))
    drop-shadow(-1px 1px 0 rgba(255, 255, 255, 0.1));
}
.catKeyAct_fighting_extinction_species .sectionAvatar > img {
  -webkit-filter: none;
}
</style>
