export default {
  data: () => ({}),

  computed: {},

  methods: {
    createTooltip(e) {
      try {
        this.clearTooltip();
        let coordinates = e.features[0].geometry.coordinates.slice();

        let title;

        if (e.features[0]?.properties?.isGeomLinePolyLayer) {
          coordinates = e.features[0]?.geometry?.coordinates[0][0];

          if (e.features[0]?.properties?.toolTipTitle) {
            title = e.features[0].properties.toolTipTitle;
          }
        }

        let defaultTooltipLabelField = null;
        defaultTooltipLabelField = this?.appSettings?.defaultTooltipLabelField;

        if (
          defaultTooltipLabelField &&
          e.features[0]?.properties &&
          e.features[0]?.properties[defaultTooltipLabelField]
        ) {
          title = e.features[0]?.properties[defaultTooltipLabelField];
        } else if (e.features[0]?.properties?.Point_Name) {
          title = e.features[0].properties.Point_Name;
        } else if (e.features[0]?.properties?.marker_point_name) {
          title = e.features[0].properties.marker_point_name;
        } else if (e.features[0]?.properties?.marker_indexs) {
          title = e.features[0].properties.marker_indexs
            .split(",")
            .slice(0, 3)
            .filter(
              index =>
                (index || index == 0) &&
                !isNaN(index) &&
                this.sites[parseFloat(index)]
            )
            .map(index => this.sites[parseFloat(index)]?.properties?.Point_Name)
            .join(", ");
        } else if (e.features[0]?.properties?.toolTipTitle) {
          title = e.features[0].properties.toolTipTitle;
        }

        if (title) {
          try {
            title = title
              .split(",")
              .map(item => {
                let words = item.trim().split(" ");
                if (!isNaN(words[words.length - 1].trim())) {
                  words.pop();
                }
                return words.join(" ");
              })
              .filter(index => index || (index == 0 && index != ""))
              .join(", ");
          } catch (e) {
            //do ntohing
          }

          this.toolTipAct = new window.mapboxgl.Popup({
            className: "pointPopupTooltip",
            //closeOnMove: true
            closeButton: false,
            anchor: "top" //offset: [0, -20],
          })
            .setLngLat(coordinates)
            .setHTML(`<div class="mvToolTip">${title}</div>`)
            .addTo(this.map);
        }
      } catch (error) {
        //do nothing
        if (!error) {
          //do nothing
        }
      }
    },
    clearTooltip() {
      try {
        if (this.toolTipAct) {
          this.toolTipAct.remove();
          this.toolTipAct = null;
        }
      } catch (error) {
        //do nothing
        if (!error) {
          //do nothing
        }
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
