<template>
  <div
    id="mvStatsPanelWrapper"
    v-show="hide != true"
    v-if="brandStats.Total && brandStats.Total.keys"
  >
    <div
      class="mvStatsPanelShowButtonWrapper"
      v-if="!showStatsPanel"
      style="  margin-left: -10px!important;"
    >
      <v-btn
        title="Show Legend"
        z-depth="0"
        depressed
        notsmall
        class="ml-2 pa-0"
        :color="!showStatsPanel ? appSettings.filterLegendButtonColor : 'white'"
        @click="showStatsPanel = !showStatsPanel"
      >
        <v-icon v-if="showStatsPanel">mdi-table </v-icon>
        <v-icon v-if="!showStatsPanel" color="white">mdi-table </v-icon>
      </v-btn>
    </div>

    <v-card
      id="mvStatsPanel"
      :color="transparentBackground ? null : 'primary'"
      v-show="showStatsPanel"
    >
      <v-card-text class="accent--text main pb-0 pt-1">
        <div class="d-flex flex-row justify-space-between">
          <b style="font-size:17.5px!important;padding-left:75px"
            >Ascott Australasia {{ extraTitle }}</b
          >

          <v-btn
            icon
            @click="showStatsPanel = false"
            title="Close Legend"
            x-small
            dense
            dark
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <table>
          <tr>
            <th class="leftText" style="width:138px;">
              Brand
            </th>
            <th class="">
              Properties
            </th>
            <th class="">
              Keys
            </th>
          </tr>

          <tr v-for="item in brandStatsHack" :key="`brandStat_${item.title}`">
            <td class="leftText">
              <b>
                {{ item.title }}
              </b>
            </td>
            <td class="rightText">{{ item.locations.toLocaleString() }}</td>
            <td class="rightText">{{ item.keys.toLocaleString() }}</td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
//import { eventBus } from "../../main";
//import LegendItem from "./LegendItem.vue";

import { mapState } from "vuex";
//import TableDataView from '../info/TableDataView.vue';
export default {
  name: "mapStats",
  components: {
    //TableDataView
    //LegendItem
  },

  props: {
    drawer: Boolean
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      regionStats: state => state.stats.regionStats,
      brandStats: state => state.stats.brandStats,
      currentZoom: state => state.navigation.currentZoom,

      range: state => state.filters.range
    }),
    extraTitle() {
      if (this.range[0] === 0 && this.range[1] === 0) {
        return "Current";
      } else if (
        this.range[0] === this.range[1] ||
        this.range[0] < this.range[1]
      ) {
        return "2030";
      } else {
        return "";
      }
    },
    brandStatsHack() {
      let brandStats = JSON.parse(JSON.stringify(this.brandStats));

      if (this.extraTitle === "2030") {
        //delete brandStats["Sydney Central"];
        //delete brandStats["Somerset"];
      }

      return brandStats;
    }
  },
  data: () => ({
    hide: true,
    showStatsPanel: true,
    transparentBackground: false,
    dontImportSlugs: ["keepertalks"]
  }),
  methods: {
    remoteClose() {
      this.showLegend = false;
    }
  },
  created() {},
  beforeDestroy() {},

  watch: {
    currentZoom(currentZoom) {
      if (currentZoom > 4.5) {
        if (!this.hide) {
          this.hide = true;
        }

        if (this.showStatsPanel) {
          //this.showStatsPanel = false;
        }
      } else {
        if (this.hide) {
          this.hide = false;
        }
      }
    }
  }
};
</script>

<style scoped>
#mvStatsPanelWrapper {
  position: absolute;
  bottom: 44px;
  left: 10px;
}

@media only screen and (max-width: 960px) {
  #mvStatsPanelWrapper {
    position: absolute;
    bottom: 39px;
    left: 10px;
  }
}

#mvStatsPanelShowButtonWrapper {
}

#mvStatsPanel {
  height: auto;
  width: 380px;

  border-radius: 4px;
}

b,
table {
  font-size: 16px !important;
}

th {
  xpadding-right: 16px;
  width: 100px;
}

th.leftText {
  xbackground-color: red;
}

.leftText {
  text-align: left;
  xpadding-right: 16px;
}
.rightText {
  text-align: right;
  padding-right: 16px;
}
.centerText {
  text-align: left;
}

tr:first-child {
  border-bottom: 2px solid white;
}

tr:last-child {
  border-top: 2px solid white;
}

#mvStatsPanel {
  background: #b7b7b770;
  padding: 10px;

  div.main,
  div.main font {
    color: white;
    xtext-shadow: #000 1px 0 4px;
  }
}
</style>
