var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.siteSectorsSlugsArray.length),expression:"siteSectorsSlugsArray.length"}],staticClass:"pb-2"},[_c('div',{staticClass:"d-flex   flex-row flex-wrap",staticStyle:{"height":"40px"}},_vm._l((_vm.siteSectorsSlugsArray),function(industrySlug){return _c('div',{key:("ind_" + industrySlug)},[(
          _vm.sectorsLookup[industrySlug] &&
            _vm.sectorsLookup[industrySlug].name &&
            _vm.sectorsLookup[industrySlug].featured_image_obj &&
            _vm.sectorsLookup[industrySlug].featured_image_obj.href
        )?_c('div',{staticClass:"pr-2",attrs:{"title":_vm.sectorsLookup[industrySlug] && _vm.sectorsLookup[industrySlug].name
            ? 'Neighbouring Industry | ' + _vm.sectorsLookup[industrySlug].name
            : ''}},[(
            _vm.sectorsLookup[industrySlug].featured_image_obj &&
              _vm.sectorsLookup[industrySlug].featured_image_obj.href
          )?_c('img',{staticStyle:{"max-width":"40px","max-height":"40px"},attrs:{"src":("" + (_vm.sectorsLookup[industrySlug].featured_image_obj.href))}}):_vm._e()]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }