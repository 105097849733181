<template>
  <div v-show="siteSectorsSlugsArray.length" class="pb-2">
    <div class="d-flex   flex-row flex-wrap" style="height: 40px;">
      <div
        v-for="industrySlug in siteSectorsSlugsArray"
        :key="`ind_${industrySlug}`"
      >
        <div
          :title="
            sectorsLookup[industrySlug] && sectorsLookup[industrySlug].name
              ? 'Neighbouring Industry | ' + sectorsLookup[industrySlug].name
              : ''
          "
          class="pr-2"
          v-if="
            sectorsLookup[industrySlug] &&
              sectorsLookup[industrySlug].name &&
              sectorsLookup[industrySlug].featured_image_obj &&
              sectorsLookup[industrySlug].featured_image_obj.href
          "
        >
          <img
            style="max-width: 40px;max-height: 40px;"
            v-if="
              sectorsLookup[industrySlug].featured_image_obj &&
                sectorsLookup[industrySlug].featured_image_obj.href
            "
            :src="`${sectorsLookup[industrySlug].featured_image_obj.href}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "IndustryList",
  components: {
    //  maptivateInfoButton
  },
  props: {
    properties: Object,
    allTaxonomiesLookup: Object
  },
  computed: {
    ...mapState({
      //allTaxonomiesLookup: state => state.markers.allTaxonomiesLookup
    }),

    sectorsLookup() {
      return this?.allTaxonomiesLookup?.all?.sectors?.lookup;
    },

    siteSectorsSlugsArray() {
      return this?.properties?.postTaxonomy?.taxSlugs?.sectors;
    }
  },

  methods: {
    localAsClick(item) {
      eventBus.$emit("nav_map_localAsFlyTo", item);
    }
  },
  async mounted() {}
};
</script>

<style scoped>
.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 20px;
}
</style>
