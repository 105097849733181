<template>
  <div
    v-if="
      properties.structuredData &&
        properties.structuredData.value &&
        properties.structuredData.value.M_NU_OPEN_YEAR &&
        properties.structuredData.value.M_NU_OPEN_YEAR.value
    "
  >
    <div>
      <b>Open Year:</b>
      {{ properties.structuredData.value.M_NU_OPEN_YEAR.value }}
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ApartmentValue",
  components: {
    //  maptivateInfoButton
  },
  props: {
    properties: Object
  },
  computed: {
    ...mapState({
      //allTaxonomiesLookup: state => state.markers.allTaxonomiesLookup
    })
  },

  methods: {},
  async mounted() {}
};
</script>

<style scoped></style>
