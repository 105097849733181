export default new (class {
  createLocalAsLayer({
    parentThis,
    map,
    dataIdName,
    layerIdName,
    appSettings
  }) {
    if (parentThis && map && dataIdName && layerIdName && appSettings) {
      //do nothingk;
    }

    parentThis.hoverStatePointLayerIds[layerIdName] = layerIdName;

    parentThis.tooltipPointLayerIds[layerIdName] = layerIdName;

    parentThis.mobileClickTooltipPointLayerIds[layerIdName] = layerIdName;

    let layerSitUnder = null;

    map.addLayer(
      {
        id: layerIdName,
        type: "symbol",
        source: dataIdName,

        minzoom: 10,
        //filter: filter.unclustered_layer,

        paint: {
          "icon-opacity": {
            stops: [
              [13, 0.7],
              [15, 0.85],
              [17, 1]
            ]
          }
        },
        layout: {
          "icon-anchor": "bottom",
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-image": ["get", "icon_id"],
          "icon-size": {
            stops: [
              [10, 0.1],
              [11, 0.13],
              [13, 0.2],
              [17, 0.4]
            ]
          }
        }
      },
      layerSitUnder
    );
  }
})();
