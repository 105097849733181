<template>
  <div
    v-if="
      properties.structuredData &&
        properties.structuredData.value &&
        properties.structuredData.value.M_RN_status &&
        properties.structuredData.value.M_RN_status.value
    "
  >
    <div>
      <b>Completion Status:</b>
      {{
        properties.structuredData.value.M_RN_status.value == 1
          ? "Future"
          : "Under Construction"
      }}
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "CompletionStatusValues",
  components: {
    //  maptivateInfoButton
  },
  props: {
    properties: Object
  },
  computed: {
    ...mapState({
      //allTaxonomiesLookup: state => state.markers.allTaxonomiesLookup
    })
  },

  methods: {},
  async mounted() {}
};
</script>

<style scoped></style>
