//import _, { initial } from "lodash";

import { mapState } from "vuex";

function registerControlPosition(map, positionName) {
  if (map._controlPositions[positionName]) {
    return;
  }
  var positionContainer = document.createElement("div");
  positionContainer.className = `mapboxgl-ctrl-${positionName}`;
  map._controlContainer.appendChild(positionContainer);
  map._controlPositions[positionName] = positionContainer;
}

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      maplayerdata: state => state.global.maplayerdata
    })
  },

  methods: {
    addMapZoomFullScreen() {
      registerControlPosition(this.map, "top-right-right");

      this.map.addControl(new window.mapboxgl.NavigationControl(), "top-left");

      // Add fullscreen control to the top-right corner
      this.map.addControl(new window.mapboxgl.FullscreenControl(), "top-left");
    },
    addAddressSearch() {
      var theIntervId = setInterval(
        () => {
          //https://docs.mapbox.com/api/search/search-box/
          //https://docs.mapbox.com/mapbox-search-js/api/web/search/#mapboxsearchbox#options
          //https://docs.mapbox.com/mapbox-gl-js/api/markers/
          if (window.MapboxSearchBox && window.MapboxSearchBox != "notReady") {
            const searchBox = new window.MapboxSearchBox();
            searchBox.accessToken = this.appSettings.map.accessToken;

            let bbox = this.appSettings.map.bounds;

            searchBox.placeholder = "Address Search";
            searchBox.options = {
              country: "AU",
              //types: "address, poi, district, place, locality, neighborhood",
              proximity: [144.946457, -37.840935],
              bbox
            };

            /*
            var svgNS = "http://www.w3.org/2000/svg"; 
            var svg = document.createElementNS(svgNS, "svg");
            
            // Set attributes for the SVG element
            svg.setAttribute("width", "40");
            svg.setAttribute("height", "40");
            
            // Create a rectangle (blue box)
            var rect = document.createElementNS(svgNS, "rect");
            rect.setAttribute("width", "40");
            rect.setAttribute("height", "40");
            rect.setAttribute("fill", "blue");
            
            // Append the rectangle to the SVG element
            svg.appendChild(rect);
            
            // Convert the SVG to an HTML string
            var svgHTML = new XMLSerializer().serializeToString(svg);
            
            // Store the HTML string in a variable
            var svgHTMLString = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgHTML);

            console.log(svgHTMLString);


            var div = document.createElement('div');

            // Set styles for the div element (optional)
            div.style.width = '40px';
            div.style.height = '40px';
            div.style.display = 'flex';
            div.style.justifyContent = 'center';
            div.style.alignItems = 'center';
            div.style.fontSize = '24px';
            div.style.backgroundColor = 'lightblue';
            
            // Set the content of the div to the letter "O"
            div.innerHTML = 'O';
            

        
            //searchBox.marker = false;
            searchBox.marker = {
              //color: "red",
              rotation: 45,
              className: "theQuickBrownFox",
              element:div,
              //scale: "10",
              
            };
*/

            searchBox.mapboxgl = window.mapboxgl;
            //this.map.addControl(searchBox, "top-right-right");
            this.map.addControl(searchBox, "top-left");

            clearInterval(theIntervId);

            searchBox?.addEventListener("retrieve", () => {
              window.mvAnalyticEvent("addressSearchResult_Clicked");
            });

            this.addMapZoomFullScreen();
          }
        },

        10
      );
    }
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {}
};
