//import { _ } from "core-js";

const getters = {};
const actions = {
  filters_enableLayer({ state, commit }, layer) {
    let layers = JSON.parse(JSON.stringify(state.layers));

    if (layer && !layers.includes(layer)) {
      layers.push(layer);
      commit("UPDATE_LAYERS", layers);
    }
  },

  filters_set_visible_post_ids({ commit }, value) {
    commit("SET_VISIBLE_POST_IDS", value);
  },

  filters_controlsUpdateLayers({ commit }, value) {
    commit("UPDATE_LAYERS", value);
  },
  filters_controlsUpdateMapboxLayers({ commit }, value) {
    commit("UPDATE_MAPBOX_LAYERS", value);
  },
  filters_controlsUpdateRange({ commit }, value) {
    commit("UPDATE_RANGE", value);
  },
  filters_controlsUpdateProgramRadio({ commit }, value) {
    commit("UPDATE_PROGRAM_RADIO", value);
  },

  filters_controlsUpdate_regionSelect({ commit }, value) {
    commit("UPDATE_REGION_SELECT", value);
  },
  filters_controlsUpdate_countrySelect({ commit }, value) {
    commit("UPDATE_COUNTRY_SELECT", value);
  },
  filters_controlsUpdate_awardSelect({ commit }, value) {
    commit("UPDATE_AWARD_SELECT", value);
  },

  filters_updateData({ commit }, wpData) {
    const categoryLookup = wpData.categoryLookup;

    const categoryArray = _.values(categoryLookup);

    let bools = categoryArray.reduce(function(map, obj) {
      map[obj.key] = true;
      return map;
    }, {});

    commit("SET_selected", { bools: bools });

    var layers = _.values(categoryLookup)
      .filter(layer => layer.hidden_by_default != true)
      .map(layer => layer.key);

    commit("SET_defaultLayers", layers);
    //commit("SET_layers", _.keys(categoryLookup));
    commit("SET_layers", layers);

    commit("SET_setup", { bools: [...categoryArray] });

    commit("SET_allTaxonomiesLookup", wpData.taxonomiesByPostType);
  },

  filters_resetLayers({ state, commit }, onlyMakerfilters) {
    commit("UPDATE_LAYERS", state.defaultLayers);
    commit("UPDATE_RANGE", state.defaultRange);
    commit("UPDATE_PROGRAM_RADIO", state.defaultProgramRadio);
    commit("UPDATE_REGION_SELECT", state.defaultRegionSelect);
    commit("UPDATE_COUNTRY_SELECT", state.defaultCountrySelect);
    commit("UPDATE_AWARD_SELECT", state.defaultAwardSelect);

    if (!onlyMakerfilters) {
      commit("UPDATE_showStatusLabels", state.default_showStatusLabels);
      commit("UPDATE_FLYTOBBONFILTER", state.default_flyToBbOnFilter);
      commit("UPDATE_minimzeInterface", state.default_minimzeInterface);
      commit("UPDATE_MAPBOX_LAYERS", state.defaultMapboxLayers);
    }
  },
  filters_controlsUpdate_flyToBbOnFilter({ commit }, value) {
    commit("UPDATE_FLYTOBBONFILTER", value);
  },
  filters_controlsUpdate_minimzeInterface({ commit }, value) {
    commit("UPDATE_minimzeInterface", value);
  },
  filters_controlsUpdate_showStatusLabels({ commit }, value) {
    commit("UPDATE_showStatusLabels", value);
  }
};

const mutations = {
  UPDATE_MAPBOX_LAYERS(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.mapboxLayers));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.mapboxLayers = JSON.parse(JSON.stringify(value));
    }
  },

  UPDATE_LAYERS(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.layers));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.layers = JSON.parse(JSON.stringify(value));
    }
  },
  UPDATE_RANGE(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.range));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.range = JSON.parse(JSON.stringify(value));
    }
  },
  UPDATE_PROGRAM_RADIO(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.programRadio));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.programRadio = JSON.parse(JSON.stringify(value));
    }
  },

  UPDATE_REGION_SELECT(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.regionSelect));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.regionSelect = JSON.parse(JSON.stringify(value));
    }
  },

  UPDATE_COUNTRY_SELECT(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.countrySelect));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.countrySelect = JSON.parse(JSON.stringify(value));
    }
  },

  UPDATE_FLYTOBBONFILTER(state, value) {
    state.flyToBbOnFilter = value;
  },
  UPDATE_minimzeInterface(state, value) {
    state.minimzeInterface = value;
  },
  UPDATE_showStatusLabels(state, value) {
    state.showStatusLabels = value;
  },
  UPDATE_AWARD_SELECT(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.awardSelect));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.awardSelect = JSON.parse(JSON.stringify(value));
    }
  },

  SET_VISIBLE_POST_IDS(state, newValue) {
    state.visibleMarkerPostIds = newValue;
  },
  SET_selected(state, newValue) {
    state.selected = newValue;
  },
  SET_defaultLayers(state, newValue) {
    state.defaultLayers = newValue;
  },
  SET_layers(state, newValue) {
    state.layers = newValue;
  },
  SET_setup(state, newValue) {
    state.setup = newValue;
  },
  SET_allTaxonomiesLookup(state, newValue) {
    //state.allTaxonomiesLookup = newValue;

    let oldValue = JSON.parse(JSON.stringify(state.allTaxonomiesLookup));
    if (oldValue !== JSON.parse(JSON.stringify(newValue))) {
      state.allTaxonomiesLookup = JSON.parse(JSON.stringify(newValue));

      if (newValue?.markers?.hotel_programs?.array) {
        let output = [];
        newValue?.markers?.hotel_programs?.array.forEach(row => {
          let { count, name, slug: key } = row;
          if (count) {
            output.push({ key, name });
          }
        });
        if (output.length) {
          state.programRadioOptions = output;
        }
      }

      if (newValue?.markers?.state?.array) {
        let output = [];
        newValue?.markers?.state?.array.forEach(row => {
          let { count, name, slug: key } = row;
          if (count) {
            //do nothing;
          }
          output.push({ key, name });
        });
        if (output.length) {
          state.regionSelectOptions = output;
        }
      }

      if (newValue?.markers?.country?.array) {
        let output = [];
        newValue?.markers?.country?.array.forEach(row => {
          let { count, name, slug: key } = row;
          if (count) {
            //do nothing;
          }
          output.push({ key, name });
        });
        if (output.length) {
          state.countrySelectOptions = output;
        }
      }

      if (newValue?.markers?.award?.array) {
        let output = [];
        newValue?.markers?.award?.array.forEach(row => {
          let { count, name, slug: key } = row;
          if (count) {
            //do nothing;
          }
          output.push({ key, name });
        });
        if (output.length) {
          state.awardSelectOptions = output;
        }
      }
    }
    //    if(programRadioOptions[])
  }
};

import { _ } from "core-js";

const state = {
  visibleMarkerPostIds: [],

  allTaxonomiesLookup: {},
  selectable: {},
  selected: {
    bools: {}
    //{ "Prospective Families": true, Wayfinding: true, History: true }
  },

  defaultLayers: [], //["History", "Prospective Families", "Wayfinding"],
  layers: [], //["History", "Prospective Families", "Wayfinding"],
  setup: {
    bools: []
  },

  defaultRange: [0, 2],
  range: [0, 2],

  defaultProgramRadio: "all",
  programRadio: "all",
  programRadioOptions: [],

  defaultRegionSelect: "all",
  regionSelect: "all",
  regionSelectOptions: [],

  defaultCountrySelect: "all",
  countrySelect: "all",
  countrySelectOptions: [],

  defaultAwardSelect: "all",
  awardSelect: "all",
  awardSelectOptions: [],

  flyToBbOnFilter: false,
  default_flyToBbOnFilter: false,

  minimzeInterface: true,
  default_minimzeInterface: true,

  showStatusLabels: true,
  default_showStatusLabels: true,

  mapboxStyleFilters: [
    {
      id: "Transport",
      title: "Transport Layer",
      layerIdPrefix: "om_transport_",
      color: "#8f2a32"
      //defaultValue: true,
    },
    {
      id: "Context",
      title: "Context Layer",
      layerIdPrefix: "om_context_",
      color: "#a9b2a3"
      //defaultValue: true,
    }
  ],
  mapboxLayers: ["Transport", "Context"],
  defaultMapboxLayers: ["Transport", "Context"],

  mapStyleExtraFilters: {
    /*
    water: {
      id: 10001,
      key: "water",
      count: 1,
      title: "water",
      name: "water",
      section_slug: "water",
      taxonomy: "basemapStyle",
      color: "red",
      section_type: "basemapStyle",
      visible: true,
      linkedToMakerLayer: false
    },
    waterway: {
      id: 10001,
      key: "waterway",
      count: 1,
      title: "Waterway",
      name: "waterway",
      section_slug: "waterway",
      taxonomy: "basemapStyle",
      color: "red",
      section_type: "basemapStyle",
      visible: true,
      linkedToMakerLayer: false
    },
    
    "basemapStyle_vic-o-transport-routes-bus-61o0ng": {
      id: 10002,
      key: "basemapStyle_vic-o-transport-routes-bus-61o0ng",
      count: 1,
      title: "BUS Lanes",
      name: "basemapStyle_vic-o-transport-routes-bus-61o0ng",
      section_slug: "basemapStyle_vic-o-transport-routes-bus-61o0ng",
      taxonomy: "basemapStyle",
      color: "red",
      section_type: "basemapStyle",
      visible: false,
      linkedToMakerLayer: "move"
    }
*/
    //these are used to link mapbox styles to the layer controls.
    /*
    //user format for object key, id, key as basemapStyle_${mapbox style layer id}
    "basemapStyle_principal-bicycle-network-pbn-01": {
      id: 10001,
      key: "basemapStyle_principal-bicycle-network-pbn-01",
      count: 1,
      title: "Bike Lanes",
      name: "basemapStyle_principal-bicycle-network-pbn-01",
      section_slug: "basemapStyle_principal-bicycle-etwork-pbn-01",
      taxonomy: "basemapStyle",
      color: "red",
      section_type: "basemapStyle",
      visible: true, 
      linkedToMakerLayer:"move"
    }, 
    
    "basemapStyle_vic-o-transport-routes-bus-01": {
      id: 10001,
      key: "basemapStyle_vic-o-transport-routes-bus-01",
      count: 1,
      title: "Bus Lines",
      name: "basemapStyle_vic-o-transport-routes-bus-01",
      section_slug: "basemapStyle_vic-o-transport-routes-bus-01",
      taxonomy: "basemapStyle",
      color: "blue",
      section_type: "basemapStyle",
      visible: true, 
      linkedToMakerLayer:"move"
    }*/
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
