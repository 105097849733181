var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:{
    currentCountryNotEnglish: _vm.currentCountryNotEnglish,
    'my-app': true,
    isApple: _vm.isIOS,
    isComputer: !_vm.$vuetify.breakpoint.mdAndDown,
    isTabletOrComputer: !_vm.$vuetify.breakpoint.smAndDown,
    isSmallComputer: !_vm.$vuetify.breakpoint.smAndDown,
    isSmallPcOrTabletOrMobile: _vm.$vuetify.breakpoint.mdAndDown,
    isTabletOrMobile: _vm.$vuetify.breakpoint.mdAndDown,
    isTablet: _vm.$vuetify.breakpoint.mdOnly,
    minimzeInterface: _vm.minimzeInterface,
    hideDetails: _vm.currentZoom < 2.5,

    showDetails: _vm.currentZoom >= 2.5
  }},[(_vm.ready)?_c('Layout'):_vm._e(),(true)?_c('AboutModal',{staticClass:"mr-8",attrs:{"isReady":_vm.ready,"showButton":false}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }