<template>
  <div
    v-if="
      properties.structuredData &&
        properties.structuredData.value &&
        properties.structuredData.value.M_NU_BEDS &&
        properties.structuredData.value.M_NU_BEDS.value
    "
  >
    <div>
      <b>Keys:</b>

      {{ properties.structuredData.value.M_NU_BEDS.value }}
    </div>

    <div translate="no" class="notranslate primary--text " v-if="false">
      <font
        class="text-subtitle-1 font-weight-bold	"
        style="font-size: 1.25rem !important;"
      >
        <v-icon large left color="primary">mdi-bed</v-icon>

        {{ properties.structuredData.value.M_NU_BEDS.value }}</font
      >
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "BedValueIcon",
  components: {
    //  maptivateInfoButton
  },
  props: {
    properties: Object
  },
  computed: {
    ...mapState({
      //allTaxonomiesLookup: state => state.markers.allTaxonomiesLookup
    })
  },

  methods: {},
  async mounted() {}
};
</script>

<style scoped></style>
