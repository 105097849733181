<template>
  <div v-show="currentValue && currentValue.any">
    <div v-if="currentValue && currentValue.any">
      <b class="hasHand" @click="showDetails = !showDetails"
        >Distance To Closest Location:</b
      >

      <nobr v-show="!showDetails">
        <font
          v-if="
            currentValue.any.distance &&
              currentValue.brand &&
              currentValue.brand.distance &&
              currentValue.brand.distance != currentValue.any.distance
          "
        >
          Any:
        </font>

        {{ currentValue.any.distance }}
        {{ currentValue.any.distance ? "km" : "" }}

        <font
          v-if="
            currentValue.any.distance &&
              currentValue.anyInital &&
              currentValue.anyInital.distance &&
              currentValue.anyInital.distance != currentValue.any.distance
          "
        >
          / {{ currentValue.anyInital.distance }}
          {{ currentValue.anyInital.distance ? "km" : "" }} (inc. hidden)</font
        >
      </nobr>
      <nobr v-show="!showDetails">
        <font
          v-if="
            currentValue.any.distance &&
              currentValue.brand &&
              currentValue.brand.distance &&
              currentValue.brand.distance != currentValue.any.distance
          "
        >
          Brand: {{ currentValue.brand.distance }}
          {{ currentValue.brand.distance ? "km" : "" }}

          <font
            v-if="
              currentValue.brand.distance &&
                currentValue.brandInital &&
                currentValue.brandInital.distance &&
                currentValue.brandInital.distance != currentValue.brand.distance
            "
          >
            / {{ currentValue.brandInital.distance }}
            {{ currentValue.brandInital.distance ? "km" : "" }} (inc. hidden)
          </font>
        </font>
      </nobr>
    </div>
    <div v-if="showDetails">
      <div v-for="row in currentValue" :key="row.set">
        {{ row.setTitle.replace("(", "").replace(")", "") }} - {{ row.distance
        }}{{ row.distance ? "km" : "" }} - {{ row.properties.Point_Name }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "MarkerDistanceValues",
  components: {
    //  maptivateInfoButton
  },
  data: () => ({
    showDetails: false
  }),
  props: {
    properties: Object
  },
  computed: {
    ...mapState({
      distanceCalculations: state => state.markers.distanceCalculations
    }),

    currentValue() {
      let output = null;

      try {
        let index = this?.properties?.index;

        if (
          !isNaN(index) &&
          this.distanceCalculations &&
          this.distanceCalculations[index]
        ) {
          output = this.distanceCalculations[index];
        }
      } catch (error) {
        console.error({ error });
      }

      return output;
    }
  },

  methods: {},
  async mounted() {}
};
</script>
<style>
.hasHand:hover {
  cursor: pointer;
}
</style>
