import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {
  iconfont: "md"
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#a3bba0", //updated
        secondary: "595959", //text
        accent: "#FFF", //link text  //updated
        error: "#595959", //item se
        info: "#595959", //selectedListItems
        success: "#595959",
        warning: "#595959", //marker text

        header: "#a3bba0",
        tabs: "#96a992"
      }
    }
  }
});
