<template>
  <div v-show="currentValue">
    <v-card-text>
      <b>As Local As You Like It</b>
      <v-list dense class="px-0 mx-0">
        <v-list-item
          dense
          class=""
          v-for="(item, i) in localAs_pros"
          :key="i"
          @click="localAsClick(item)"
        >
          <v-list-item-icon
            dense
            class="mr-3 my-0"
            style="    align-self: center;"
          >
            <img
              v-show="item.list_image_obj && item.list_image_obj.thumb"
              :src="`${item.list_image_obj.thumb}`"
            />
          </v-list-item-icon>

          <v-list-item-content dense class="my-0 py-0">
            <v-list-item-title
              class="ma-0 pa-0 "
              v-text="item.Point_Name"
            ></v-list-item-title>
          </v-list-item-content> </v-list-item
      ></v-list>

      <div
        class="pb-2"
        v-if="
          properties.structuredData &&
            properties.structuredData.note &&
            properties.structuredData.note
              .M_TA_as_local_as_you_like_it_details &&
            properties.structuredData.note.M_TA_as_local_as_you_like_it_details
              .value
        "
        v-html="
          properties.structuredData.note.M_TA_as_local_as_you_like_it_details
            .value
        "
      ></div>
    </v-card-text>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "LocalAsYouLikeItList",
  components: {
    //  maptivateInfoButton
  },
  props: {
    properties: Object
  },
  computed: {
    ...mapState({
      distanceCalculations: state => state.markers.distanceCalculations,
      geoJson_local_as_you_like_it: state =>
        state.markers.geoJson_local_as_you_like_it
    }),

    localAs_features() {
      let markers = this?.geoJson_local_as_you_like_it?.data?.features?.filter(
        row => {
          return (
            row?.properties?.M_LINKED_POST_linkedMarker ===
            this?.properties?.post_id
          );
        }
      );
      markers = markers?.length ? markers : [];

      return markers;
    },

    localAs_pros() {
      let markers = this?.localAs_features?.map(row => row.properties);

      return markers;
    },

    currentValue() {
      return this.localAs_pros.length ? true : false;
    }
  },

  methods: {
    localAsClick(item) {
      eventBus.$emit("nav_map_localAsFlyTo", item);
    }
  },
  async mounted() {}
};
</script>

<style scoped>
.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 35px;
}

.v-list-item--dense .v-list-item__icon img,
.v-list-item--dense .v-list-item__icon,
.v-list-item--dense .v-list-item-icon img,
.v-list-item--dense .v-list-item-icon {
  height: 30px;
  width: 30px;
}
</style>
